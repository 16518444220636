import { t } from 'i18next';

export function priceFmt(val, decimals = 1, minDecimals = 0) {
  const value = Number(val);
  if (!value) return '0';
  return value.toLocaleString(undefined, {
    minimumFractionDigits: minDecimals, // value % 1 > 0 ? minDecimals : 0,
    maximumFractionDigits: decimals,
  });
}

export function priceFmtThousands(val, decimals = 1, minDecimals = 0) {
  const value = val >= 1000 ? val / 1000 : val;
  const thousands = val >= 1000 ? 'k' : '';

  //if (decimals >= 0) return `${Number(value?.toFixed(decimals))}${thousands}`;
  if (decimals >= 0)
    return `${value.toLocaleString(undefined, {
      minimumFractionDigits: minDecimals,
      maximumFractionDigits: decimals,
    })}${thousands}`;
  if (decimals < 0) {
    if (value % 1 > 0)
      return `${Number(value?.toFixed(-decimals))}${thousands}`;
    else return `${value?.toFixed(0)}${thousands}`;
  }
}

export function parseMillisecondsLeft(milliseconds) {
  //if (milliseconds < 0) return;
  var temp = Math.floor(milliseconds / 1000);
  //var years = Math.floor(temp / 31536000);
  var days = Math.floor((temp %= 31536000) / 86400);
  var hours = `${Math.floor((temp %= 86400) / 3600)}`.padStart(2, '0');
  var minutes = `${Math.floor((temp %= 3600) / 60)}`.padStart(2, '0');
  var seconds = `${temp % 60}`.padStart(2, '0');
  return { days, hours, minutes, seconds };
}

export function millisecondsLeftToStr(milliseconds, isFullFormat = false) {
  const { days, hours, minutes, seconds } = parseMillisecondsLeft(milliseconds);

  if (isNaN(hours) || isNaN(minutes) || isNaN(seconds)) return;
  if (isFullFormat) {
    return `${t('Time left')}: ${days}:${hours}:${minutes}:${seconds}`;
  } else {
    if (isNaN(days) || days === 0)
      return `${t('Time left')}: ${hours}:${minutes}:${seconds}`;
    else return `${t('Days left')}: ${days + 1}`;
  }
}

export function getEndOfWeek(isUTC = true) {
  // Get current date
  const curr = new Date();

  // First day is the day of the month - the day of the week
  const first = isUTC
    ? curr.getUTCDate() - ((curr.getUTCDay() + 6) % 7)
    : curr.getDate() - ((curr.getDay() + 6) % 7);

  const last = first + 6; // last day is the first day + 6

  //firstday = new Date(curr.setDate(first)).toUTCString();
  const lastDay = isUTC
    ? new Date(curr.setUTCDate(last))
    : new Date(curr.setDate(last));

  const lastDayTime = lastDay.getTime();
  const day24Hours = 86400000; // 86400000=24*60*60*1000
  const endOfWeekTime = lastDayTime - (lastDayTime % day24Hours) + day24Hours; // 86400000=24*60*60*1000
  return endOfWeekTime;
}

export function sleep(delay) {
  return new Promise((res) => setTimeout(res, delay));
}

export function isInAppBrowser() {
  const userAgent = navigator.userAgent || navigator.vendor;
  const isFb = userAgent.indexOf('FB_IAB') > 0 || userAgent.indexOf('FBAN') > 0;
  const isInstagram = userAgent.indexOf('Instagram') > 0;
  return isFb || isInstagram;
}

export function isIos() {
  const userAgent = navigator.userAgent || navigator.vendor;
  return userAgent.indexOf('iPhone') >= 0 || userAgent.indexOf('iPad') >= 0;
}

export function isAndroid() {
  const userAgent = navigator.userAgent || navigator.vendor;
  return userAgent.indexOf('Android') >= 0;
}

export function isPwa() {
  return (
    window.matchMedia('(display-mode: standalone)').matches ||
    window.navigator.standalone === true
  );
}

export function isNativeApp() {
  const userAgent = navigator.userAgent;
  const isBoxo = userAgent.indexOf('BOXOAPP') >= 0;
  return isBoxo;
}

// Function to convert Base64URL to Uint8Array
export const urlBase64ToUint8Array = (base64String) => {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }

  return outputArray;
};

export function tryToParseJSON(str) {
  try {
    return str ? JSON.parse(str) : {};
  } catch (e) {
    return { e };
  }
}

export function stringifyCycledJSON(obj) {
  var seen = [];
  return JSON.stringify(obj, function (_, val) {
    if (val != null && typeof val == 'object') {
      if (seen.indexOf(val) >= 0) {
        return;
      }
      seen.push(val);
    }
    return val;
  });
}

export function compareVersions(version1, version2) {
  const v1Parts = version1 ? version1.split('.').map(Number) : [];
  const v2Parts = version2 ? version2.split('.').map(Number) : [];
  const maxLength = Math.max(v1Parts.length, v2Parts.length);
  for (let i = 0; i < maxLength; i++) {
    const v1 = v1Parts[i] || 0;
    const v2 = v2Parts[i] || 0;
    if (v1 > v2) return 1;
    if (v1 < v2) return -1;
  }
  return 0;
}
