import { t } from 'i18next';
import { SelectionList } from '../components/terminal';
import { useBonusesContext } from '../shared/hooks/use-bonus';
import { useLocation, useNavigate } from 'react-router-dom';
import { ModalLayout } from '../shared/layout';
import { isNativeApp, priceFmt } from '../shared/utils';
import { useLoggerContext } from '../shared/hooks/use-logger';
import { useUserContext } from '../shared/hooks/use-user';
import Shop from './shop';
import { useEffect, useState } from 'react';

const Balance = () => {
  if (isNativeApp() || process.env.NODE_ENV !== 'production')
    return <BalanceShop />;
  else return <BalanceCrypto />;
};

const BalanceShop = () => {
  return <Shop />;
};

const BalanceCrypto = () => {
  const { balance, user } = useUserContext();
  const email = user?.email;
  const logger = useLoggerContext();
  const navigate = useNavigate();
  const location = useLocation();

  const [bonuses, setBonuses] = useState([]);
  const { getActualBonuses } = useBonusesContext();
  useEffect(() => {
    const init = async () => {
      const newBonuses = await getActualBonuses();
      setBonuses(newBonuses);
    };
    init();
  }, []);

  function Done() {
    navigate('/app', { replace: true });
  }

  return (
    <ModalLayout
      onClose={() => {
        if (location.state) navigate(location.state, { replace: true });
        else navigate('/app', { replace: true });
      }}
    >
      <div className="modal-header">
        <h1 className="text-center text-default">{t('Balance')}</h1>

        <h1
          className="text-center text-default"
          style={{
            fontSize: '11vh',
            fontWeight: 100,
            lineHeight: '11vh',
            marginTop: '2vh',
          }}
        >
          {priceFmt(balance, 1)}
        </h1>

        <div className="subtitle text-center text-muted">
          ≈${priceFmt((balance * 1) / 1000, balance < 0.01 ? 4 : 2)}
        </div>
      </div>
      <div className="modal-body">
        {/* false &&
          bonuses.map((b, idx) => (
            <BonusItem
              key={idx}
              amount={b.amount}
              name={b.name}
              descr={b.descr}
              expiration={b.expiration}
              onClick={() => {
                logger &&
                  logger.action('claim-bonus', {
                    amount: b.amount,
                    descr: b.name,
                    step: 1,
                  });

                if (b.name === 'Bonus') {
                  onBonus(b);
                  Done();
                }
                if (b.name === 'Deposit bonus') {
                  navigate('/app/deposit', {
                    replace: true,
                    state: location.pathname,
                  });
                }
              }}
            />
          )) */}
        <SelectionList
          items={[
            {
              name: t('Buy'),
              descr: isNativeApp() ? t('Boxo packs') : t('Deposit crypto'),
              imgUrl: '/img/deposit.svg',
              type: 'menu',
            },
            /*{
              name: (
                <span>
                  {t('Cash out USDT')}
                  <img
                    style={{ width: '20px', margin: '-5px 3px 0px' }}
                    src="/img/usdt.svg"
                  />
                </span>
              ),
              descr: t('Min') + ' $25',
              imgUrl: '/img/withdraw.svg',
              type: 'menu',
            },
            */
            {
              name: t('Bonuses'),
              descr: t('Claim'),
              imgUrl: '/img/gift.svg',
              type: 'menu',
              tag: bonuses?.length > 0 ? bonuses.length : null,
              /* tag:
                bonuses?.filter((b) => !b.last_seen_at).length > 0
                  ? bonuses?.filter((b) => !b.last_seen_at).length
                  : null,
              tagMuted:
                bonuses?.filter((b) => !b.last_seen_at).length === 0
                  ? bonuses.length
                  : null, 
              */
            },
          ]}
          onClick={(idx) => {
            if (idx === 0)
              navigate(isNativeApp() ? '/app/shop' : '/app/deposit', {
                replace: true,
                state: location.pathname,
              });
            /* if (idx === 1)
              navigate('/app/withdraw', {
                replace: true,
                state: location.pathname,
              });
            */
            if (idx === 1)
              navigate('/app/bonus', {
                replace: true,
                state: location.pathname,
              });
          }}
        />
        <br />
        <SelectionList
          header={email}
          items={[
            {
              name: t('Settings'),
              descr: t('Account'),
              imgUrl: '/img/settings.svg',
              type: 'menu',
            },
          ]}
          onClick={(idx) => {
            if (idx === 0)
              navigate('/app/settings', {
                replace: true,
                state: location.pathname,
              });
          }}
        />
      </div>
      <div className="modal-footer no-mobile-keyboard justify-content-center">
        <div
          className="btn btn-secondary desktop-only"
          role="button"
          onClick={() => {
            Done();
          }}
        >
          {t('Close')}
        </div>
      </div>
    </ModalLayout>
  );
};

export { Balance };
