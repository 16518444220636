import {
  handleMiddlewareError,
  maybe_refresh_auth_token_middlware,
} from './utils';

export async function persistUserProfile(profile) {
  const { data } = await maybe_refresh_auth_token_middlware((api) =>
    api.post('/api/user/profile', {
      ...profile,
      baseUrl:
        process.env.NODE_ENV === 'production'
          ? window.location.origin
          : 'https://boxo.trade',
    })
  ).catch(handleMiddlewareError);
  return data;
}

export async function getUserProfile() {
  const { data } = await maybe_refresh_auth_token_middlware((api) =>
    api.get('/api/user/profile')
  ).catch(handleMiddlewareError);
  return data;
}

export async function getUserLeaderboard() {
  const { data } = await maybe_refresh_auth_token_middlware((api) =>
    api.get('/api/user/leaderboard')
  ).catch(handleMiddlewareError);
  return data?.leaderboard;
}

export async function getUserLeague() {
  const { data } = await maybe_refresh_auth_token_middlware((api) =>
    api.get('/api/user/league')
  ).catch(handleMiddlewareError);
  return data;
}

export async function getUserWinProgress() {
  const { data } = await maybe_refresh_auth_token_middlware((api) =>
    api.get('/api/user/win-progress')
  ).catch(handleMiddlewareError);
  return data;
}

export async function persistPushSubscription(subscription, baseUrl) {
  const { data } = await maybe_refresh_auth_token_middlware((api) =>
    api.post('/api/push/subscribe', {
      subscription,
      baseUrl,
    })
  ).catch(handleMiddlewareError);
  return data;
}
