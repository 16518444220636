import {
  compareVersions,
  isAndroid,
  isIos,
  isNativeApp,
  isPwa,
} from '../utils';
import { useNativeAppContext } from './use-native-app';
import AppleLogin from 'react-apple-login';
import { t } from 'i18next';
import { BtnIcon } from '../../components/terminal';
import { signInWithApple } from '../api/auth';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export function useSignIn() {
  const baseUrl =
    process.env.NODE_ENV === 'production'
      ? `${window.location.protocol}//${window.location.host}`
      : 'http://localhost:3000';

  const nativeApp = useNativeAppContext();

  const google = () => {
    const REDIRECT_URI = `${baseUrl}/app/auth/google`;
    const redirectUrl = '';
    const CLIENT_ID =
      '539433954908-9n7okuge3lobv4i30sgfekl8pc19k045.apps.googleusercontent.com';
    const uri = `https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&scope=https://www.googleapis.com/auth/userinfo.email%20https://www.googleapis.com/auth/userinfo.profile&access_type=offline&state=1234_purpleGoogle&prompt=consent`;

    if (!isNativeApp() && !isPwa()) window.location.href = uri; //Stay in the same window. Works good for web.
    if (isPwa()) {
      if (isAndroid()) window.location.href = uri; //Android does not automatically close new window
      if (isIos()) window.open(uri, '_blank'); //This version does not let using existing Google accounts
      //if (isIos()) window.location.href = `x-safari-${uri}` //This version can not return user to PWA from Safari
    }

    if (isNativeApp()) nativeApp.openWebBrowser(uri, 'auth', redirectUrl);
  };

  // Pure web flow is supported
  // PWA is supported similar to web (ios -> new window)
  // Native App only for Andorid (testing)
  const isGoogleSignInSupported =
    (!isPwa() && !isNativeApp()) ||
    isPwa() ||
    (isNativeApp() && isAndroid()) ||
    (isNativeApp() && compareVersions(nativeApp?.version, '1.5.0') >= 0);

  const isAppleSignInSupported = isGoogleSignInSupported; //process.env.NODE_ENV !== 'production';

  return {
    google,
    isGoogleSignInSupported,
    isAppleSignInSupported,
  };
}

export function GoogleSignInButton() {
  const { google } = useSignIn();
  return (
    <div
      className="btn btn-secondary mt-2"
      role="button"
      onClick={() => {
        google();
      }}
    >
      <BtnIcon src="/img/btn-google.svg" />
      {t(`Continue with Google`)}
    </div>
  );
}
export function AppleSignInButton() {
  const baseUrl =
    process.env.NODE_ENV === 'production'
      ? `${window.location.protocol}//${window.location.host}`
      : 'https://boxo.trade';
  const REDIRECT_URI = `${baseUrl}/app/auth/apple`;
  const CLIENT_ID = 'boxo.trade'; //'com.BoxoApp';

  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const onAppleResponse = (response) => {
    /*
      Example: 
      response = {
        "authorization": {
          "code": "cf5702498a7b94c1789d149fa49ed5ec6.0.rruxz.uuPTUiM9nIOoanx7HfHmzg",
          "id_token": "eyJraWQiOiJwZ2duUWVOQ09VIiwiYWxnIjoiUlMyNTYifQ.eyJpc3MiOiJodHRwczovL2FwcGxlaWQuYXBwbGUuY29tIiwiYXVkIjoiYm94by50cmFkZSIsImV4cCI6MTczMDcyMzMxMywiaWF0IjoxNzMwNjM2OTEzLCJzdWIiOiIwMDE0NzkuZmRkZTQ1ODQ0YWYwNGZlNTk3MThmZmVmMWZkZjA5ZDYuMTIxMiIsImNfaGFzaCI6IlczcTNEWnZxVklXVXpmTFc0N1pfZmciLCJlbWFpbCI6Ijg1c3Rzbm5wbjVAcHJpdmF0ZXJlbGF5LmFwcGxlaWQuY29tIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsImlzX3ByaXZhdGVfZW1haWwiOnRydWUsImF1dGhfdGltZSI6MTczMDYzNjkxMywibm9uY2Vfc3VwcG9ydGVkIjp0cnVlfQ.d8Gn0OdoKAddcwIXSweiWlTKmLUskLdE_DYFWzoJp794v8CauVVY057Q9lXkPaBoWHWNj5TBtTr2H0wS-v6sgaA-mleo_K7weTTlvvDjUci9eWdc-nWfMRyLxi1VY0wpW_fINCHYQS_nNIrgV78upGmL8uv0ejz5Ngi2Oju02Ri7kWlgCTwj3ddEvUlxWGtTud2Un47I1ZC_hOiJyPupObHfiqIo4al1BeS-4X7Cfqj12CYgzNVHforBDc4hZClm2gmJXNwiJpaP_2vJ9JxriPOMp4da2BXlWEeHjL3fnPTRQ0bY3eqmCXqJujHeKjVXJtXRcAmWT7MjoF_4G6q4wg"
        }
      }
    */

    setIsLoading(false);
    if (response?.error) {
      if (response.error.error === 'popup_closed_by_user') return;
      if (response.error.error === 'popup_blocked_by_browser') return;
      navigate(`/app/auth/apple?error=${btoa(response.error.error)}`, {
        replace: true,
      });
    } else {
      navigate(
        `/app/auth/apple?authorization=${btoa(
          JSON.stringify(response?.authorization)
        )}&redirectUri=${btoa(REDIRECT_URI)}`,
        { replace: true }
      );
    }
  };

  return (
    <AppleLogin
      clientId={CLIENT_ID}
      redirectURI={REDIRECT_URI}
      usePopup={true}
      callback={onAppleResponse}
      scope="email name"
      responseMode="query"
      render={(renderProps) => (
        <div
          className="btn btn-secondary mt-2"
          role="button"
          onClick={(e) => {
            if (!isLoading) {
              setIsLoading(true);
              renderProps.onClick(e);
            }
          }}
        >
          <BtnIcon src="/img/btn-apple.svg" />
          {t(`Continue with Apple`)}
        </div>
      )}
    />
  );
}
