import {
  handleMiddlewareError as handleError,
  maybe_refresh_auth_token_middlware,
} from './utils';
import { api_middleware } from './api-client';
import { loadPersistentState } from '../utils/persist';

export async function logSession(sessionId, deviceUserId, data) {
  if (process.env.NODE_ENV === 'production') {
    const sessionData = {
      sessionId,
      deviceUserId,
      data,
    };
    const session = loadPersistentState('session');
    if (session?.token)
      await maybe_refresh_auth_token_middlware((api) =>
        api.post('/api/log/session', sessionData)
      ).catch(handleError);
    else
      await api_middleware()
        .post('/api/log/session', sessionData)
        .catch(handleError);
  } else console.log('logSession', data);
}

export async function logEvent(event) {
  if (process.env.NODE_ENV === 'production') {
    const session = loadPersistentState('session');
    if (session?.token)
      await maybe_refresh_auth_token_middlware((api) =>
        api.post('/api/log/event', event)
      ).catch(handleError);
    else
      await api_middleware().post('/api/log/event', event).catch(handleError);
  } else console.log('logEvent', event);
}

export async function logStartRegistration(sessionId, email) {
  if (process.env.NODE_ENV === 'production')
    await api_middleware().post('/api/log/register', {
      sessionId,
      email,
    });
}

export async function logFinishRegistration() {
  const { data } = await maybe_refresh_auth_token_middlware((api) =>
    api.post('/api/log/registered', {})
  ).catch(handleError);
  return data;
}
