import React from 'react';
import BlackBox from './black-box.svg';
import BlackLid from './black-lid.svg';

export const BonusBoxSvg = ({ color = '#3FEE76', ...props }) => {
  return (
    <div {...props}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 208 121"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M103.826 0.0371094L207.639 60.5054L103.826 120.974L0.0136719 60.5054L103.826 0.0371094Z"
          fill="url(#paint0_linear_1507_228)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_1507_228"
            x1="103.826"
            y1="0.0371094"
            x2="103.826"
            y2="120.974"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F9F9F9" stopOpacity="0.56" />
            <stop offset="1" stopColor={color} stopOpacity="0.9" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
};

export const BonusBlackBoxSvg = ({ ...props }) => {
  return <img {...props} src={BlackBox} />;
};
export const BonusBlackLidSvg = ({ ...props }) => {
  return <img {...props} src={BlackLid} />;
};
