import axios from 'axios';
import { createContext, useContext, useMemo, useEffect } from 'react';
import { useSessionStorage } from './use-hooks';
import { useLoggerContext } from './use-logger';
import { useInterval } from './use-interval';
import { compareVersions, isNativeApp, tryToParseJSON } from '../utils';
import { useState } from 'react';
import { persistState } from '../utils/persist';
import { useNativeAppContext } from './use-native-app';

const Updater = createContext();
export function UpdaterContext({ children }) {
  const updater = useUpdater();
  const persistedUpdater = useMemo(() => updater, [updater]);
  return (
    <Updater.Provider value={persistedUpdater}>{children}</Updater.Provider>
  );
}
export const useUpdaterContext = () => useContext(Updater);

function useUpdater() {
  const nativeApp = useNativeAppContext();

  const logger = useLoggerContext();
  const [lastUpdateVersion, setLastUpdateVersion] = useSessionStorage(
    'lastUpdateVersion',
    null
  );
  const [updateIsRequired, setUpdateIsRequired] = useState(false);

  async function fetchSettings() {
    try {
      const response = await axios.get(`/api/settings`);
      const newSettings = tryToParseJSON(response?.data?.settings);
      persistState('settings', newSettings);
      return newSettings;
    } catch (e) {
      return { version: '0.0.0' };
    }
  }

  function isReadyForUpdate(minVersion) {
    const version = process.env.REACT_APP_VERSION;
    if (!minVersion) return false;
    if (compareVersions(minVersion, version) <= 0) return false;

    if (lastUpdateVersion === minVersion) {
      /*       logger.event('error', {
        msg: `Can not update app to the version ${minVersion}. Stayed at the version ${version}`,
      });
      */
      return false;
    }
    return true;
  }

  function updateApp() {
    fetchSettings().then((settings) => {
      const minVersion = settings?.minVersion;
      if (isReadyForUpdate(minVersion)) {
        setLastUpdateVersion(minVersion);
        const version = process.env.REACT_APP_VERSION;
        logger.event('updating', { version, minVersion });
        /* 
            .then((names) => {
              const promises = names.map((name) => caches?.delete(name));
              Promise.all(promises)
                .then(() => {
                  window.location.href = '/';
                  if (isNativeApp()) nativeApp?.updateVersion();
                })
                .catch(() => {
                  window.location.href = '/';
                  if (isNativeApp()) nativeApp?.updateVersion();
                });
            })
        */
        if (process.env.NODE_ENV !== 'production') window.location.href = '/';
        else
          caches
            ?.keys()
            .then((names) => {
              names.map((name) => caches?.delete(name));
            })
            .then(() => {
              window.location.href = '/';
              if (isNativeApp()) nativeApp?.updateVersion();
            })
            .catch(() => {
              logger.event('error', {
                msg: `Failed to clear cache`,
              });
              window.location.href = '/';
            });
      }
    });
  }

  function checkUpdate() {
    fetchSettings().then((settings) => {
      const minVersion = settings?.minVersion;
      setUpdateIsRequired(isReadyForUpdate(minVersion));
    });
  }

  //Check updates 1 minute
  useInterval(() => checkUpdate(), 60000);

  useEffect(() => {
    if (window.location.pathname === '/') updateApp();
  }, []);

  return { updateApp, updateIsRequired };
}
