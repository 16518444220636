import { loadPersistentState, persistState } from '../utils/persist';
import { handleError, maybe_refresh_auth_token } from './utils';
import { api, api_middleware } from './api-client';
import { Mutex } from 'async-mutex';

export async function register(email, password) {
  const { data } = await api()
    .post('/auth/register', { email, password })
    .catch(handleError);
  return data;
}

export async function sendVerificationCode(email) {
  const { data } = await api()
    .post('/auth/send-code', { email })
    .catch(handleError);
  return data;
}

export async function verifyEmail(email, code) {
  const { data } = await api()
    .post('/auth/verify-email', { email, code })
    .catch(handleError);
  return data;
}

export async function login(email, password) {
  const { data } = await api()
    .post('/auth/login', { email, password })
    .then((response) => {
      persistState('session', response.data);
      return response;
    })
    .catch(handleError);
  return data;
}

export async function loginWithToken(token) {
  const { data } = await api()
    .post('/auth/login', { token })
    .then((response) => {
      persistState('session', response.data);
      return response;
    })
    .catch(handleError);
  return data;
}

const mutex = new Mutex();

export async function refreshToken(oldRefreshToken) {
  const result = await mutex.runExclusive(async () => {
    const { token, refreshToken } = loadPersistentState('session');
    //Already changed (refreshed)
    if (oldRefreshToken !== refreshToken) {
      return { refreshToken, token };
    } else
      try {
        const response = await api().post('/auth/refresh-token', {
          refreshToken,
        });
        if (!response?.data) throw { error: 'failed to refresh token' };
        persistState('session', response.data);
        return response;
      } catch (e) {
        throw { error: e?.message };
      }
  });
  return result;
}

export async function logout() {
  const { data } = await maybe_refresh_auth_token((api) =>
    api.post('/auth/logout').then((response) => {
      persistState('session', null);
      return response;
    })
  ).catch(handleError);
  return data;
}

export async function updateUser(password) {
  const { data } = await maybe_refresh_auth_token(
    (api) => api.post('/auth/update-user'),
    {
      password,
    }
  ).catch(handleError);
  return data;
}

export async function deleteUser() {
  const { data } = await maybe_refresh_auth_token(
    (api) => api.post('/auth/delete-user'),
    {}
  ).catch(handleError);
  return data;
}

export async function sendResetPasswordToken(email) {
  const { data } = await api()
    .post('/auth/send-reset-password-token', { email })
    .catch(handleError);
  return data;
}

export async function resetPassword(email, token, password) {
  const { data } = await api()
    .post('/auth/reset-password', { email, token, password })
    .catch(handleError);
  return data;
}

export async function signInWithGoogle(code, redirectUri) {
  const { data } = await api_middleware()
    .post('/api/auth/google', { code, redirectUri })
    .then((response) => {
      persistState('session', response?.data?.data);
      return response;
    })
    .catch(handleError);
  return data;
}

export async function signInWithApple(authorization, redirectUri) {
  const { data } = await api_middleware()
    .post('/api/auth/apple', { authorization, redirectUri })
    .then((response) => {
      persistState('session', response?.data?.data);
      return response;
    })
    .catch(handleError);
  return data;
}
