import { t } from 'i18next';
import { useMemo } from 'react';
import { useContext } from 'react';
import { createContext } from 'react';
import { useRef } from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { deliverPurchase, fetchProducts } from '../api/products';
import { useLoggerContext } from './use-logger';

const productsDEV = [
  {
    title: 'Small pack (dev)',
    description: '3600 Boxo',
    price: '4.99',
    currency: 'USD',
    localizedPrice: `$4.99`,
    type: 'iap',
    productId: 'SmallBoxoPack',
  },
  {
    title: 'Medium pack (dev)',
    description: '8400 Boxo',
    price: '9.99',
    currency: 'USD',
    localizedPrice: `$9.99`,
    type: 'iap',
    productId: 'MediumBoxoPack',
  },
  {
    title: 'Large pack (dev)',
    description: '19900 Boxo',
    price: '19.99',
    currency: 'USD',
    localizedPrice: `$19.99`,
    type: 'iap',
    productId: 'LargeBoxoPack',
  },
];

const NativeApp = createContext();

export function NativeAppContext({ children }) {
  const NativeAppProps = useNativeApp();
  const persistedNativeAppProps = useMemo(
    () => NativeAppProps,
    [NativeAppProps]
  );
  return (
    <NativeApp.Provider value={persistedNativeAppProps}>
      {children}
    </NativeApp.Provider>
  );
}
export const useNativeAppContext = () => useContext(NativeApp);

function useNativeApp() {
  const navigate = useNavigate();
  const logger = useLoggerContext();
  const [version, setVersion] = useState(null);
  const [expoPushToken, setExpoPushToken] = useState();
  const [productsIAP, setProductsIAP] = useState([]);
  const [productsDB, setProductsDB] = useState([]);
  const [products, setProducts] = useState([]);

  //====================== IAP ============

  const getProductName = (p) => {
    switch (p?.name) {
      case 'Small Pack':
        return t('Small Pack');
      case 'Medium Pack':
        return t('Medium Pack');
      case 'Large Pack':
        return t('Large Pack');
    }
    return p?.name;
  };
  useEffect(() => {
    if (!productsIAP?.length || !productsDB?.length) return;

    const newProducts = productsDB
      .map((p) => {
        const productIAP = productsIAP.find(
          (pIAP) => pIAP.productId?.toLowerCase() === p.product_id
        );
        return {
          id: productIAP?.productId,
          name: getProductName(p),
          amount: p.amount,
          price: productIAP?.localizedPrice,
        };
      })
      .filter((p) => !p.productId)
      .sort((a, b) => a.amount * 1 - b.amount * 1);
    setProducts(newProducts);
  }, [productsDB, productsIAP]);

  const onDeliverPurchase = useRef();
  const setOnDeliverPurchase = (callback) =>
    (onDeliverPurchase.current = callback);

  const onPurchaseError = useRef();
  const setOnPurchaseError = (callback) => (onPurchaseError.current = callback);

  async function handleDeliverPurchase(purchase) {
    const { amount, error } = await deliverPurchase(purchase);
    finishPurchase(purchase);
    onDeliverPurchase.current &&
      onDeliverPurchase.current(amount, purchase, error);
  }

  async function handlePurchaseError(purchaseError) {
    logger?.event('native', { name: 'error', purchaseError });
    onPurchaseError.current && onPurchaseError.current(purchaseError);
  }

  //
  function handleSignIn(platform, result) {
    alert(`sign in on ${platform}: ${JSON.stringify(result)}`);
  }

  //========================= Push ===========

  function handlePushNotification(notification) {
    const persistPushNotificationStatus = async (id) => {
      return fetch('/api/push/update', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id }),
      });
    };

    if (!notification?.data) return;
    const url = notification?.data?.url;
    persistPushNotificationStatus(notification?.data?.id);
    navigate(url, { replace: true });
  }

  //========================= SWITCH ===========

  function handleNativeAppMessage(msg) {
    try {
      const data = JSON.parse(msg.data);
      switch (data?.method) {
        case 'pushNotication':
          handlePushNotification(data?.notification);
          break;
        case 'onDeliverPurchase':
          handleDeliverPurchase(data?.purchase);
          break;
        case 'onPurchaseError':
          handlePurchaseError(data?.purchaseError);
          break;
        case 'onSignIn':
          handleSignIn(data?.platform, data.result);
          break;
        case 'setProducts':
          if (data?.products?.length > 0) setProductsIAP(data?.products);
          else if (data?.error === 'Not ready')
            setTimeout(() => postData({ method: 'getProducts' }), 5000);
          else if (data?.error)
            logger?.event('native', {
              name: 'error',
              msg: `setProducts: ${data?.error}`,
            });
          /*
            else if (data?.products?.length === 0)
            alert(`NO PRODUCTS: ${JSON.stringify(data)}`);
         */
          break;
        case 'setExpoPushToken':
          setExpoPushToken(data?.token);
          if (getPushNotificationsCallback.current) {
            getPushNotificationsCallback.current({
              token: data?.token,
              status: data?.status,
              message: data?.message,
            });
            getPushNotificationsCallback.current = null;
          }
          break;
        case 'version':
          setVersion(data?.version);
          break;
        case 'onShowAdMob':
          showAdMobEventListener?.current &&
            showAdMobEventListener.current(data?.event);
          break;
        case 'navigate':
          setTimeout(() => {
            const baseUrl = `${window.location.protocol}//${window.location.host}`;
            const location = data?.location?.replace(baseUrl, '');
            navigate(location, { replace: true });
          }, 400);
          break;
        case 'log':
          const name = data?.event?.name;
          const eventData = data?.event?.data;
          logger?.event('native', { name, eventData });
          break;
        case 'alert':
          alert(data?.message);
          break;
        default:
          if (process.env.NODE_ENV !== 'production')
            alert('Native app calls unknown method: ' + data?.method);
      }
    } catch (_) {}
  }

  useEffect(() => {
    const messageListener = window.addEventListener(
      'message',
      (msg) => handleNativeAppMessage(msg),
      true
    );
    getPushNotifications();
    postData({ method: 'getLastPushNotification' });
    postData({ method: 'getVersion' });
    postData({ method: 'getProducts' });
    if (process.env.NODE_ENV !== 'production') setProductsIAP(productsDEV);
    fetchProducts().then(async (response) => {
      setProductsDB(response?.products);
    });
    setTimeout(() => {
      postData({ method: 'initialized' });
    }, 500);

    return () => {
      window.removeEventListener('message', messageListener);
    };
  }, []);

  function postData(data) {
    window.ReactNativeWebView?.postMessage(JSON.stringify(data));
  }

  //============================================================
  // Methods
  //============================================================

  const getPushNotificationsCallback = useRef();
  function getPushNotifications(requestPermission = false, callback = null) {
    getPushNotificationsCallback.current = callback;
    postData({ method: 'getPushNotifications', requestPermission });
  }

  function nativeAlert(message) {
    postData({ method: 'alert', message, title: 'Message' });
  }
  function vibrate(vibration) {
    postData({ method: 'vibrate', vibration });
  }
  function showAdMob(adType, adUnitId) {
    postData({
      method: 'showAdMob',
      adType,
      adUnitId,
    });
  }

  function openWebBrowser(url, type = '', redirectUrl = '') {
    //type = 'auth' for secure browser with cookies
    //redirectUrl for auth browser only
    postData({ method: 'openWebBrowser', url, type, redirectUrl });
  }

  function closeWebBrowser() {
    postData({ method: 'closeWebBrowser' });
  }

  function signIn(platform) {
    postData({ method: 'signIn', platform });
  }

  function isSignedIn(platform) {
    postData({ method: 'isSignedIn', platform });
  }

  function signOut(platform) {
    postData({ method: 'signOut', platform });
  }

  function haptics(type) {
    postData({ method: 'haptics', type });
  }

  function updateVersion() {
    postData({ method: 'updateVersion' });
  }

  function purchaseProduct(productId) {
    postData({ method: 'purchaseProduct', productId });
  }

  function finishPurchase(purchase) {
    postData({ method: 'finishPurchase', purchase });
  }

  const showAdMobEventListener = useRef();

  function addAdEventListener(event, callback) {
    if (event === 'onShowAdMob') {
      showAdMobEventListener.current = callback;
      return () => (showAdMobEventListener.current = null);
    }
  }

  return {
    version,
    products,
    purchaseProduct,
    expoPushToken,
    alert: nativeAlert,
    vibrate,
    haptics,
    showAdMob,
    addAdEventListener,
    updateVersion,
    getPushNotifications,
    setOnDeliverPurchase,
    setOnPurchaseError,
    signIn,
    signOut,
    isSignedIn,
    openWebBrowser,
    closeWebBrowser,
  };
}
