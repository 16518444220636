import { useCallback } from 'react';
import { useEffect, useState } from 'react';
import CountUp from 'react-countup';

const changeType = {
  up: 1,
  down: -1,
  both: 0,
};

const CountUpDown = ({
  value = 0,
  decimals = 1,
  animate = changeType.both,
  ...props
}) => {
  const [start, setStart] = useState();
  const [end, setEnd] = useState();

  useEffect(() => {
    setEnd(props.end);
    setStart(props.start || props.end);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const change = value > end ? changeType.up : changeType.down;

    if (
      (change >= 0 && animate === changeType.up) ||
      (change < 0 && animate === changeType.down) ||
      animate === changeType.both
    ) {
      setStart(end);
    } else {
      setStart(value);
    }
    setEnd(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const fmtFn = useCallback(
    (num) =>
      num?.toLocaleString(undefined, {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals,
      }),
    [decimals]
  );

  return (
    <CountUp
      decimals={decimals}
      useEasing={false}
      duration={2}
      {...props}
      preserveValue={false}
      start={start}
      end={end}
      formattingFn={fmtFn}
    />
  );
};

export { CountUpDown, changeType };
