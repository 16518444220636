import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { ModalLayout } from '../../shared/layout';
import { useLocalStorage } from '../../shared/hooks/use-hooks';
import { persistUserProfile } from '../../shared/api/profile';
import { useRef } from 'react';

export default function Profile({ logger, onBack, onClose }) {
  const [nickname, setNickname] = useLocalStorage('nickname', '');
  const nicknameInputRef = useRef();

  return (
    <ModalLayout onClose={onClose}>
      <div className="modal-header">
        <h1 className="text-center text-default">{t('Name')}</h1>
      </div>

      <div className="modal-body">
        <input
          ref={nicknameInputRef}
          type="email"
          id="typeEmail"
          maxLength={10}
          value={nickname}
          placeholder={t('Enter your name')}
          onKeyDown={(e) => {
            if (!e.key?.match(/[A-Za-z0-9&.&_&\- ]/)) e.preventDefault();
          }}
          onChange={() => {
            setNickname(nicknameInputRef.current.value);
          }}
        />
        {/*         <div className="invalid-feedback">{'state?.context?.error'}</div>
         */}
      </div>

      <div className="modal-footer no-mobile-keyboard">
        <div
          className={`btn btn-primary`}
          role="button"
          onClick={async () => {
            persistUserProfile({ nickname });
            onBack();
          }}
        >
          {t(`Save`)}
        </div>
      </div>
    </ModalLayout>
  );
}
