import { assign, send, log, createMachine } from 'xstate';
import { v4 as uuid } from 'uuid';

export const claimBonusMachine = createMachine({
  id: 'claimBonus',
  context: {
    bonus: null,

    isClaimed: false,
    successShown: false,
    boostUsed: false,

    claimedAmount: 0,
    error: '',
  },
  initial: 'idle',
  states: {
    idle: {
      tags: ['loading'],
      entry: [log((context) => ['idle', context])],
      on: {
        START: [
          {
            cond: (_, event) => event.payload?.useAds,
            actions: [assign({ bonus: (_, event) => event.payload })],
            target: 'adsShowing',
          },
          {
            cond: (_, event) =>
              !event.payload?.useAds && !event.payload?.useAdBoost,
            actions: [assign({ bonus: (_, event) => event.payload })],
            target: 'claimingBonus',
          },
          {
            cond: (_, event) =>
              !event.payload?.useAds && event.payload?.useAdBoost,
            actions: [assign({ bonus: (_, event) => event.payload })],
            target: 'success',
          },
        ],
      },
    },
    adsShowing: {
      tags: ['ads'],
      entry: [log((context, _) => ['adsShowing', context])],
      on: {
        DONE: [
          {
            cond: (context) => !context.bonus?.useAdBoost,
            target: 'claimingBonus',
          },
          {
            target: 'success',
          },
        ],
      },
    },

    success: {
      tags: ['success'],
      entry: [log((context, _) => ['success', context])],
      on: {
        DONE: [
          {
            cond: (context) => context.bonus?.useAdBoost,
            actions: [assign({ successShown: true })],
            target: 'boost',
          },
          {
            target: 'exit',
          },
        ],
      },
    },

    boost: {
      tags: ['select', 'boost'],
      entry: [log((context, _) => ['boost', context])],
      on: {
        CLAIM_X1: [
          {
            target: 'claimingBonus',
          },
        ],
        CLAIM_X2: [
          {
            actions: [assign({ boostUsed: true })],
            target: 'boostAdsShowing',
          },
        ],
      },
    },

    claimingBonus: {
      tags: ['loading'],
      entry: [
        log((context, event) => [
          'claimingBonus',
          event?.data?.claimedAmount,
          context,
          event,
        ]),
      ],
      invoke: {
        src: 'claimBonus',
        onDone: [
          {
            cond: (context) => context.boostUsed,
            actions: [
              assign({ claimedAmount: (_, event) => event.data.claimedAmount }),
            ],
            target: 'successBoost',
          },
          {
            cond: (context) => context.successShown,
            actions: [
              assign({ claimedAmount: (_, event) => event.data.claimedAmount }),
            ],
            target: 'exit',
          },
          {
            actions: [
              assign({ claimedAmount: (_, event) => event.data.claimedAmount }),
            ],
            target: 'success',
          },
        ],
        onError: {
          target: 'failed',
          actions: [assign({ error: (_, event) => event.data.error })],
        },
      },
    },

    exit: {
      entry: [log((context) => ['exit', context])],
      type: 'final',
    },

    boostAdsShowing: {
      tags: ['ads', 'boost'],
      entry: [log((context, _) => ['boostAdsShowing', context])],
      on: {
        DONE: [
          {
            target: 'claimingBonus',
          },
        ],
      },
    },

    successBoost: {
      tags: ['success', 'boost'],
      entry: [log((context, _) => ['successBoost', context])],
      on: {
        DONE: [
          {
            target: 'exit',
          },
        ],
      },
    },

    failed: {
      tags: ['failed'],
      entry: [log((context, _) => ['failed', context])],
      type: 'final',
    },
  },
});
