import { useMemo } from 'react';
import { useContext } from 'react';
import { useRef } from 'react';
import { createContext } from 'react';
import { useEffect, useState } from 'react';
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from 'react-circular-progressbar';
import { Loader } from '../../pages/loader';
import { loadPersistentState } from '../utils/persist';
import { useLocalStorage, useSessionStorage } from './use-hooks';
import { useInterval } from './use-interval';

const Adsense = createContext();
export function AdsenseContext({ children }) {
  const adsenseProps = useAdsense();
  const persistedAdsenseProps = useMemo(() => adsenseProps, [adsenseProps]);
  return (
    <Adsense.Provider value={persistedAdsenseProps}>
      {children}
    </Adsense.Provider>
  );
}
export const useAdsenseContext = () => useContext(Adsense);

function useAdsense() {
  const [adsEnabled, setAdsEnabled] = useState(false);

  function getAdsenseHeaderScript() {
    if (adsEnabled)
      return (
        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1711494065283317"
          crossorigin="anonymous"
        />
      );
    return <script />;
  }

  function AdsenseIns({ onShow, onFailed }) {
    useEffect(() => {
      setAdsEnabled(true);
    }, []);

    useEffect(() => {
      if (adsEnabled)
        try {
          (window.adsbygoogle = window.adsbygoogle || []).push({});
        } catch (e) {
          onFailed && onFailed(`can not push ads: ${e?.message}`);
        }
    }, [adsEnabled]);

    const insRef = useRef();

    const clearInterval = useInterval(() => {
      const adsStatus = insRef.current?.getAttribute('data-ad-status');

      if (adsStatus === 'filled') {
        clearInterval();
        onShow && onShow(adsStatus);
      }
      if (adsStatus === 'unfilled') {
        clearInterval();
        onFailed && onFailed(adsStatus);
      }
    }, 800);

    const settings = loadPersistentState('settings');

    return (
      <>
        {adsEnabled && !settings?.adsenseSlot && (
          <ins //Default ads slot
            ref={insRef}
            className="adsbygoogle"
            style={{ display: 'block', width: '100%', height: '90vh' }}
            data-ad-client="ca-pub-1711494065283317"
            data-ad-slot="7685259450"
            data-ad-format="auto"
            data-full-width-responsive="true"
          />
        )}

        {adsEnabled && settings?.adsenseSlot === '7685259450' && (
          <ins //Default ads slot with no size
            ref={insRef}
            className="adsbygoogle"
            style={{ display: 'block' }}
            data-ad-client="ca-pub-1711494065283317"
            data-ad-slot="7685259450"
            data-ad-format="auto"
            data-full-width-responsive="true"
          />
        )}

        {adsEnabled && settings?.adsenseSlot === '6635851334' && (
          <ins //Multiplex
            ref={insRef}
            class="adsbygoogle"
            style={{ display: 'block' }}
            data-ad-client="ca-pub-1711494065283317"
            data-ad-slot="6635851334"
            data-ad-format="autorelaxed"
            /*data-matched-content-ui-type="image_card_sidebyside"
            data-matched-content-rows-num="5"
            data-matched-content-columns-num="1"
             */
          />
        )}
      </>
    );
  }

  return { getAdsenseHeaderScript, AdsenseIns };
}

export function AdsenseContainer({
  playTime = 6,
  onDone = () => {},
  onFail = () => {},
  onShowing = () => {},
}) {
  const { AdsenseIns } = useAdsenseContext();
  const [adsStartTime, setAdsStartTime] = useLocalStorage(
    'show-ads-start-time',
    null
  );
  const [hide, setHide] = useState(false);

  const [preventAdClicks, setPreventAdClicks] = useState(true);
  useEffect(() => {
    const newPrevent = Math.random() > 0.1;
    setPreventAdClicks(newPrevent);
  }, []);

  const doDone = function () {
    setHide(true);
    setAdsStartTime(null);
    document
      .querySelector('meta[name=viewport]')
      .setAttribute(
        'content',
        'width=device-width, height=device-height, initial-scale=1, maximum-scale=1, user-scalable=no, shrink-to-fit=no, viewport-fit=cover'
      );
    setTimeout(() => {
      onDone();
    }, 600);
  };

  const showProgress =
    adsStartTime && (Date.now() - adsStartTime) / 1000 <= playTime;
  return (
    <>
      {false && adsStartTime === null && <Loader overlay />}

      <div
        style={{
          display: hide ? 'none' : 'default',
          position: 'relative',
          zIndex: 0,
        }}
      >
        <div
          style={{
            position: 'relative',
          }}
        >
          <div
            style={{
              position: 'absolute',
              width: '100vw',
              top: '-1rem',
              left: '50%',
              transform: 'translate(-50%, -1rem)',
            }}
          >
            <AdsenseIns
              onShow={() => {
                setAdsStartTime(Date.now());
                onShowing();
              }}
              onFailed={(status) => {
                if (process.env.NODE_ENV === 'production') {
                  onFail(status);
                } else {
                  setAdsStartTime(Date.now());
                }
              }}
            />
          </div>
        </div>

        <div
          style={{
            position: 'relative',
            top: 0,
            left: '-0.5rem',
            width: preventAdClicks ? '100%' : '40px',
            height: preventAdClicks ? '100vh' : '40px',
          }}
          onClick={() => {
            if (!!adsStartTime && !showProgress) doDone();
            if (!adsStartTime && !showProgress) onFail('exit');
            if (process.env.NODE_ENV !== 'production') {
              if (showProgress) window.open('https://google.com', '_top');
            }
          }}
        >
          <div
            style={{
              width: '40px',
              height: '40px',
            }}
          >
            <CircularProgressbarWithChildren
              value={adsStartTime ? (Date.now() - adsStartTime) / 1000 : 0}
              maxValue={playTime}
              styles={buildStyles({
                strokeLinecap: 'butt',
                pathTransitionDuration: 0.3,
                pathColor: showProgress ? `var(--theme-muted)` : 'transparent',
                trailColor: showProgress
                  ? 'var(--theme-muted-transparent)'
                  : 'transparent',
                backgroundColor: 'transparent',
              })}
            >
              <img
                style={{
                  position: 'relative',
                  width: '30px',
                }}
                src="/img/close.svg"
              />
            </CircularProgressbarWithChildren>
          </div>
        </div>
      </div>
    </>
  );
}
