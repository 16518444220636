import { useEffect, createContext, useMemo } from 'react';
import Terminal from './pages/terminal';
import Landing from './pages/landing';
import Header from './shared/header';
import { useLoggerContext } from './shared/hooks/use-logger';
import { Route, Routes } from 'react-router-dom';
import NotFound from './pages/404';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { useLocalStorage } from './shared/hooks/use-hooks';
import { SoundContext } from './shared/hooks/use-sound';
import { InstallPromptContext, Install } from './pages/install';
import Quiz from './pages/quiz';

export default function App() {
  const [lng, setLng] = useLocalStorage('lng', null);
  const [_, setLngDetected] = useLocalStorage('lng-detected', null);

  useEffect(() => {
    if (lng && lng !== 'auto') i18next.changeLanguage(lng); //remove auto legacy
    else {
      window.localStorage.removeItem('i18nextLng');
      i18next.use(LanguageDetector).init({
        keySeparator: '>',
        nsSeparator: '|',
      });
      setLngDetected(i18next.language);
    }
    const newLng = i18next.language;
    document.documentElement.lang = newLng;
    setLng(newLng);
  }, [lng]);

  const logger = useLoggerContext();

  return (
    <InstallPromptContext>
      <SoundContext>
        <Routes>
          <Route path="/install" element={<Install logger={logger} />} />
          <Route
            path="/installing"
            element={<Install installing={true} logger={logger} />}
          />
          <Route
            path="/installed"
            element={<Install hasInstalled={true} logger={logger} />}
          />
          <Route
            path="/autoInstall"
            element={<Install autoInstall={true} logger={logger} />}
          />
          <Route path="*" element={<AppLayout logger={logger} />} />
        </Routes>
      </SoundContext>
    </InstallPromptContext>
  );
}

function AppLayout({}) {
  useEffect(() => {
    window.Telegram?.WebApp?.disableVerticalSwipes();
    window.Telegram?.WebApp?.expand();
    const color = '#212b34';
    window.Telegram?.WebApp?.setHeaderColor(color);
    window.Telegram?.WebApp?.setBackgroundColor(color);
    window.Telegram?.WebApp?.setBottomBarColor(color);
    window.Telegram?.WebApp?.enableClosingConfirmation();
  }, [window.Telegram, window.Telegram?.WebApp]);

  return (
    <div className="app" id="boxo-app">
      <Header title="Boxo Game" backgroundColor="#212b34" />
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/app/*" element={<Terminal />} />
        <Route path="/quiz/*" element={<Quiz />} />
        <Route path="/*" element={<NotFound />} />
      </Routes>
    </div>
  );
}
