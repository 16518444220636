import { t } from 'i18next';
import { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useWssPrices } from '../shared/hooks/use-wssPrices';
import { ModalLayout } from '../shared/layout';
import { priceFmt } from '../shared/utils';

export default function Quiz() {
  const navigate = useNavigate();

  const [price, setPrice] = useState();

  const onPriceUpdate = (event) => {
    const newPrice = event?.price?.value;
    if (newPrice) setPrice(newPrice);
    else navigate('/', { replace: true });
  };

  useWssPrices(onPriceUpdate);

  const [k, setK] = useState(1234);
  useEffect(() => {
    setK(Math.round(Math.random() * 100));
  }, []);
  const mult = [2.1, 1, 0.6, 9];

  const options = [
    { name: 'A', val: price * mult[(k + 1) % 4] },
    { name: 'B', val: price * mult[(k + 2) % 4] },
    { name: 'C', val: price * mult[(k + 3) % 4] },
    { name: 'D', val: price * mult[(k + 4) % 4] },
  ];
  const [answer, setAnswer] = useState(-1);

  return (
    <>
      <ModalLayout onClose={null}>
        <div className="modal-header text-center">
          <h1 className="h1-pixel text-default">BOXO</h1>
          <div>
            <small>{t('Box Options Game')}</small>
          </div>
        </div>
        <div className="modal-body text-center">
          <div
            style={{
              backgroundColor: 'var(--theme-overlay-transparent)',
              borderRadius: '16px',
              padding: '20px',
            }}
          >
            <div
              style={{
                backgroundColor: 'var(--theme-overlay-accent)',
                padding: '10%',
              }}
            >
              <img width="35%" src="/img/btc.svg" />
            </div>

            <div className="mt-4 mb-2">
              <h2 className="text-default">{t('What is Bitcoin price?')}</h2>
              <small className="text-default">
                {t('Please select the current price of the Bitcoin (BTC/USD)')}
              </small>
            </div>
          </div>
        </div>

        <div className="modal-footer no-mobile-keyboard justify-content-center">
          {!!price && (
            <div className="">
              <div className="row g-3">
                {options.map((option, idx) => {
                  return (
                    <div className="col-6" key={idx}>
                      <div
                        className="btn btn-primary text-monospace"
                        role="button"
                        style={{
                          opacity: answer === idx || answer < 0 ? 1 : 0.1,
                          outline:
                            answer === idx
                              ? option.val === price
                                ? `4px solid var(--theme-primary)`
                                : `4px solid var(--theme-red)`
                              : '',
                        }}
                        onClick={() => {
                          if (answer === idx) setAnswer(-1);
                          else if (answer < 0) setAnswer(idx);
                          else return;
                        }}
                      >
                        <span className="text-default">
                          {`${option.name}`}:{' '}
                        </span>
                        <span>{`${priceFmt(option.val, 0, 0)}`}</span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </ModalLayout>
    </>
  );
}
