import { minidenticon } from 'minidenticons';
import { useMemo } from 'react';
//import { ReactComponent as LeagueIconSvg } from '../../img/cube.svg';

export function Avatar({ username, ...props }) {
  const saturation = 50;
  const lightness = 70;
  const svgURI = useMemo(
    () =>
      'data:image/svg+xml;utf8,' +
      encodeURIComponent(minidenticon(username, saturation, lightness)),
    [username, saturation, lightness]
  );
  return <img fill="red" src={svgURI} alt={username} {...props} />;
}

export function LeagueIcon({ isLocked, color, ...props }) {
  const svgLock = (
    <svg
      width="70"
      height="78"
      {...props}
      viewBox="0 0 70 78"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.4007 0.468818C35.4725 -0.0670802 34.3289 -0.0670799 33.4007 0.468818L2.27344 18.4401C1.34523 18.976 0.773438 19.9664 0.773438 21.0382V56.9808C0.773438 58.0526 1.34523 59.043 2.27344 59.5789L33.4007 77.5502C34.3289 78.0861 35.4725 78.0861 36.4007 77.5502L67.5279 59.5789C68.4561 59.043 69.0279 58.0526 69.0279 56.9808V21.0382C69.0279 19.9664 68.4561 18.976 67.5279 18.4401L36.4007 0.468818ZM43.1995 29.9659C43.1995 33.3855 41.1312 36.3221 38.1773 37.5928L41.428 57.0037H28.4276L31.6447 37.6016C28.6798 36.3358 26.6018 33.3934 26.6018 29.9659C26.6018 25.3826 30.3174 21.667 34.9007 21.667C39.484 21.667 43.1995 25.3826 43.1995 29.9659Z"
        fill="black"
      />
    </svg>
  );
  const svg = (
    <svg
      {...props}
      width="118"
      height="117"
      viewBox="0 0 118 117"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M57.6245 1.11651C58.5527 0.580616 59.6963 0.580615 60.6245 1.11651L108.073 28.5112C109.002 29.0471 109.573 30.0375 109.573 31.1092V85.8986C109.573 86.9704 109.002 87.9607 108.073 88.4966L60.6245 115.891C59.6963 116.427 58.5527 116.427 57.6245 115.891L10.1756 88.4966C9.24737 87.9607 8.67557 86.9704 8.67557 85.8986V31.1092C8.67557 30.0375 9.24737 29.0471 10.1756 28.5112L57.6245 1.11651Z"
        fill={color}
      />
      <path
        d="M59.041 0.250488L109.035 29.3708L59.041 58.4912L9.04688 29.3708L59.041 0.250488Z"
        fill="url(#paint0_linear_1373_33)"
        fillOpacity="0.28"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1373_33"
          x1="59.041"
          y1="0.250488"
          x2="59.041"
          y2="58.4912"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F9F9F9" stopOpacity="0" />
          <stop offset="1" stopColor="#C2C2C2" />
        </linearGradient>
      </defs>
    </svg>
  );

  return isLocked ? svgLock : svg;
}
