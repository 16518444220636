import { t } from 'i18next';
import { SelectionList } from '../components/terminal';
import { useBonusesContext } from '../shared/hooks/use-bonus';
import { useLocation, useNavigate } from 'react-router-dom';
import { ModalLayout } from '../shared/layout';
import { priceFmt } from '../shared/utils';
import { useUserContext } from '../shared/hooks/use-user';
import { useLoggerContext } from '../shared/hooks/use-logger';
import { useNativeAppContext } from '../shared/hooks/use-native-app';
import { Loader } from './loader';
import { useEffect, useState } from 'react';

export default function Shop({}) {
  const { products, purchaseProduct, setOnPurchaseError } =
    useNativeAppContext();
  const navigate = useNavigate();
  const location = useLocation();
  const logger = useLoggerContext();
  const { balance } = useUserContext();

  const { getActualBonuses } = useBonusesContext();
  const [bonuses, setBonuses] = useState([]);
  useEffect(() => {
    getActualBonuses().then((newBonuses) => setBonuses(newBonuses));
  }, []);

  function Done() {
    navigate('/app', { replace: true });
  }

  const [isLoading, setIsLoading] = useState(false);

  const onPurchaseError = (error) => {
    setIsLoading(false);
    if (error.code === 'E_USER_CANCELLED') return;

    alert('onPurchaseError ' + JSON.stringify(error));
  };
  setOnPurchaseError(onPurchaseError);
  const nativeApp = useNativeAppContext();

  function bonusOnClick(bonus) {
    if (!bonus) return;
    //onSetBonus(bonus);
    if (
      bonus.typeId === '2ndWelcomeBonusFreeBoxes' ||
      bonus.typeId === 'WelcomeBonusFreeBoxes'
    ) {
      navigate('/app/bonus/welcome', {
        replace: true,
        state: location.pathname,
      });
    }

    if (bonus.typeId === 'PushNotificationsBonus') {
      navigate('/app/bonus/push', {
        replace: true,
        state: location.pathname,
      });
    }

    if (bonus.typeId === 'ChallengeBonus') {
      navigate('/app/bonus/challenge', {
        replace: true,
        state: location.pathname,
      });
    }
    if (bonus.typeId === 'RecoveryBonus') {
      navigate('/app/bonus/recovery', {
        replace: true,
        state: location.pathname,
      });
    }
    if (bonus.typeId === 'AdBonus') {
      navigate('/app/bonus/health', {
        replace: true,
        state: location.pathname,
      });
    }
  }

  return (
    <>
      {isLoading && <Loader />}
      <ModalLayout
        actionName={t('Settings')}
        onAction={() => {
          navigate('/app/settings', {
            replace: true,
            state: location.pathname,
          });
        }}
        onClose={() => {
          if (location.state) navigate(location.state, { replace: true });
          else navigate('/app', { replace: true });
        }}
      >
        <div className="modal-header">
          <h1 className="text-center text-default">{t('Balance')}</h1>
          <div className="subtitle text-center text-muted">
            <small className="text-center text-muted">BOXO</small>
          </div>

          <h1
            className="text-center text-default -primary-accent"
            style={{
              fontSize: '7vh',
              fontWeight: 300,
              lineHeight: '7vh',
              //marginTop: '0vh',
            }}
          >
            {priceFmt(balance, balance < 1000 ? 1 : 0)}
          </h1>

          {/*           <div className="subtitle text-center text-muted">
           ≈${priceFmt((balance * 1) / 1000, balance < 0.01 ? 4 : 2)}             
          </div>
 */}
        </div>
        <div className="modal-body">
          {bonuses?.length > 0 && (
            <SelectionList
              header={t('Bonuses')}
              items={bonuses.map((bonus, idx) => {
                const { name, descr, imgUrl, disabled } = bonus;
                return {
                  name,
                  descr,
                  imgUrl,
                  disabled,
                  tag: bonus?.isNew ? t('New') : null,
                  type: 'menu',
                };
              })}
              onClick={(idx) => bonusOnClick(bonuses[idx])}
            />
          )}
          <br />
          {products?.length > 0 && (
            <SelectionList
              header={t('Boxo shop')}
              items={products.map((p) => {
                return {
                  id: p.id,
                  name: p.name,
                  descr: `${priceFmt(p.amount, 0)} BOXO`,
                  imgUrl: `/img/${p.id?.toLowerCase()}.svg`,
                  type: 'button',
                  tagMuted: `${p.price}`,
                };
              })}
              onClick={(id) => {
                setIsLoading(true);
                purchaseProduct(id);
                nativeApp.haptics('selection');
              }}
            />
          )}
          {/*
          <br />
           <SelectionList
            header={t('Special offers')}
            items={[
              {
                name: t('Bonuses'),
                descr: t('Claim'),
                imgUrl: '/img/gift.svg',
                type: 'menu',
                tag: bonuses?.length > 0 ? bonuses.length : null,
              },
            ]}
            onClick={(idx) => {
              if (idx === 0)
                navigate('/app/bonus', {
                  replace: true,
                  state: location.pathname,
                });
            }}
          />
        */}
        </div>
        <div className="modal-footer no-mobile-keyboard justify-content-center">
          <div
            className="btn btn-secondary desktop-only"
            role="button"
            onClick={() => {
              Done();
            }}
          >
            {t('Close')}
          </div>
        </div>
      </ModalLayout>
    </>
  );
}
