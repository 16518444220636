import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLoggerContext } from '../shared/hooks/use-logger';
import { loadPersistentState } from '../shared/utils/persist';

export default function Ref() {
  const logger = useLoggerContext();
  const navigate = useNavigate();

  useEffect(() => {}, []);

  const settings = loadPersistentState('settings');
  return <div></div>;
}
