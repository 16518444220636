import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { ModalLayout } from '../../shared/layout';
import { useLocalStorage } from '../../shared/hooks/use-hooks';
import { persistUserProfile } from '../../shared/api/profile';
import { logout } from '../../shared/api/auth';
import { useRef } from 'react';
import { SelectionList } from '../../components/terminal';
import { useUserContext } from '../../shared/hooks/use-user';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { SystemDialog } from '../../shared/dialogs';
import { Avatar } from '../../components/icons';

export default function Account({ logger, onBack, onClose, onLogout }) {
  const [nickname, setNickname] = useLocalStorage('nickname', '');
  const [showDialog, setShowDialog] = useState(false);
  const { user } = useUserContext();
  const email = user?.email;
  const userName = nickname ? nickname : email?.split('@').shift();

  const navigate = useNavigate();
  const location = useLocation();

  return (
    <>
      <ModalLayout onClose={onClose}>
        <div className="modal-header">
          <h1 className="text-center text-default">{t('Account')}</h1>
        </div>
        <div className="modal-body">
          <div
            style={{
              left: '50%',
              position: 'relative',
              transform: 'translateX(-50%)',
              width: '130px',
              height: '130px',
              borderRadius: '50%',
              backgroundColor: 'var(--theme-overlay-transparent)',
              padding: '20px',
            }}
          >
            <Avatar
              username={email}
              style={{
                height: '90px',
                width: '90px',
              }}
            />
          </div>

          <br />
          <SelectionList
            items={[
              {
                name: t('Name'),
                descr: nickname,
                imgUrl: '/img/user.svg',
                type: 'menu',
              },
              {
                name: t('Delete account'),
                imgUrl: '/img/delete.svg',
                type: 'menu',
              },
            ]}
            onClick={(idx) => {
              if (idx === 0)
                navigate('./profile', {
                  replace: true,
                  state: location.pathname,
                });
              if (idx === 1)
                navigate('./delete', {
                  replace: true,
                  state: location.pathname,
                });
            }}
          />

          <br />
          <SelectionList
            header={email}
            items={[
              {
                name: t('Logout'),
                imgUrl: '/img/logout.svg',
                type: 'button',
              },
            ]}
            onClick={(idx) => {
              setShowDialog(true);
            }}
          />
        </div>

        <div className="modal-footer no-mobile-keyboard">
          <div className="mt-2 text-center">
            <img
              src="/img/shield.svg"
              alt=""
              style={{
                marginTop: '-2px',
                marginRight: '4px',
                height: '16px',
              }}
            />
            <small className="text-primary btn-link">
              <Link state={location.pathname} to="./privacy" replace>
                Privacy policy
              </Link>
            </small>
            {'  '}
            <small className="text-muted">
              {'Boxo © 2024 Inc. All Rights Reserved'}
            </small>
          </div>
        </div>
      </ModalLayout>

      <SystemDialog
        title={`${t('Logout')}?`}
        show={showDialog}
        message={t('Are you sure you want to logout?')}
        actions={[t(`Cancel`), t('Logout')]}
        onAction={async (idx) => {
          setShowDialog(false);
          if (idx === 1) {
            logger?.event('click', { target: 'logout' });
            await logout();
            onLogout && onLogout('click');
          }
        }}
      />
    </>
  );
}
