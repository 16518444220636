import { t } from 'i18next';
import { ModalLayout } from '../../shared/layout';
import { deleteUser } from '../../shared/api/auth';
import { useUserContext } from '../../shared/hooks/use-user';
import { priceFmt } from '../../shared/utils';
import { useState } from 'react';
import { SystemDialog } from '../../shared/dialogs';

export default function Delete({ onClose, onLogout }) {
  const { balance, user } = useUserContext();
  const email = user?.email;

  const [showDialog, setShowDialog] = useState(false);

  return (
    <>
      <ModalLayout onClose={onClose}>
        <div className="modal-header">
          <h1 className="text-center text-default">{t('Delete account')}</h1>
          <div className="text-center">
            <div className="text-muted">{email}</div>
            <div className="text-muted">
              {t('Balance')} {priceFmt(balance, 2, 2)} BOXO
            </div>
          </div>
        </div>

        <div className="modal-body text-center">
          <div
            style={{
              display: 'inline-block',
              backgroundColor: 'var(--theme-red-transparent)',
              borderRadius: '20px',
            }}
          >
            <div
              className="text-center text-red"
              style={{ margin: '8px 12px' }}
            >
              <img
                src="/img/i.svg"
                alt=""
                style={{
                  //display: 'inline-block',
                  marginTop: '-4px',
                  marginRight: '6px',
                  height: '26px',
                }}
              />

              {t('This can not be undone')}
            </div>
          </div>

          <img
            src="/img/delete-red.svg"
            alt=""
            height={'80px'}
            style={{
              position: 'fixed',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)',
              display: 'block',
              margin: '3vh auto',
            }}
          />
        </div>

        <div className="modal-footer no-mobile-keyboard">
          <div
            className={`btn btn-primary`}
            style={{
              '--bs-btn-bg': 'var(--theme-red)',
              '--bs-btn-active-bg': 'var(--theme-red-light)',
              '--bs-btn-hover-bg': 'var(--theme-red-light)',
              color: 'var(--theme-default)',
            }}
            role="button"
            onClick={async () => {
              setShowDialog(true);
            }}
          >
            {t(`Delete`)}
          </div>
        </div>
      </ModalLayout>

      <SystemDialog
        title={`${t('Delete account')}?`}
        show={showDialog}
        message={t('Are you sure you want to delete your account?')}
        actions={[t(`Cancel`), t('Delete')]}
        onAction={async (idx) => {
          setShowDialog(false);
          if (idx === 1) {
            await deleteUser();
            onLogout && onLogout();
          }
        }}
      />
    </>
  );
}
