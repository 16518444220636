import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

export default function PendingRedirect() {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const delay = params.delay || 2000;
  const target = `${location.pathname}`.replace(`/p/${delay}`, '');

  useEffect(() => {
    setTimeout(() => {
      if (location.pathname === window.location.pathname)
        navigate(target, { replace: true, state: location.state });
    }, delay);
  });

  return <></>;
}
