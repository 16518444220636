export function loadPersistentState(dbName) {
  try {
    const item = window.localStorage.getItem(dbName);
    if (item) {
      return JSON.parse(item);
    }
    return null;
  } catch (error) {
    return null;
  }
}

export function loadPersistentStateValue(dbName, key) {
  if (typeof key === 'undefined') {
    throw new Error('loadItem requires key to be passed');
  }
  const state = loadPersistentState(dbName);
  if (state === null) {
    return null;
  }
  return state[key];
}

export function persistState(dbName, state) {
  try {
    if (!state) {
      window.localStorage.removeItem(dbName);
    } else {
      window.localStorage.setItem(dbName, JSON.stringify(state));
    }
  } catch {
    console.error('error writing to local storage: ', dbName, state);
  }
}

export function persistSessionState(dbName, state) {
  try {
    if (!state) {
      window.sessionStorage.removeItem(dbName);
    } else {
      window.sessionStorage.setItem(dbName, JSON.stringify(state));
    }
  } catch {
    console.error('error writing to session storage: ', dbName, state);
  }
}

export function loadPersistentSessionState(dbName) {
  try {
    const item = window.sessionStorage.getItem(dbName);
    if (item) {
      return JSON.parse(item);
    }
    return null;
  } catch (error) {
    return null;
  }
}
