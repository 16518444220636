import { assign, log, createMachine } from 'xstate';
import validator from 'validator';
import { t } from 'i18next';
import {
  login,
  loginWithToken,
  register,
  resetPassword,
  sendResetPasswordToken,
  sendVerificationCode,
  verifyEmail,
} from './api/auth';
import { loadPersistentState } from './utils/persist';

export const resetPasswordRequestMachine = createMachine(
  {
    id: 'resetPasswordRequest',
    context: {
      email: '',
      error: '',
    },
    initial: 'enterEmail',
    states: {
      enterEmail: {
        tags: ['enterEmail'],
        entry: [log((context, _) => ['enterEmail', context])],
        on: {
          RESET_ERROR: {
            actions: assign({
              error: '',
            }),
          },
          SUBMIT: {
            actions: assign({
              email: (_, event) => event.payload.email,
            }),
            target: 'requesting',
          },
        },
      },
      requesting: {
        tags: ['enterEmail', 'loading'],
        entry: [log((context, _) => ['requesting', context])],
        invoke: {
          src: 'resetPasswordRequest',
          onDone: {
            target: 'success',
            actions: assign({
              error: '',
            }),
          },
          onError: {
            target: 'enterEmail',
            actions: assign({
              error: (context, event) => event.data,
            }),
          },
        },
      },

      success: {
        tags: ['success'],
        entry: log((context, _) => ['success', context]),
        on: {
          SUBMIT: {
            actions: assign({
              email: (_, event) => event.payload.email,
            }),
            target: 'requesting',
          },
          BACK: {
            target: 'enterEmail',
          },
        },
      },
    },
  },

  {
    services: {
      resetPasswordRequest: (context, _) =>
        new Promise(async (resolve, reject) => {
          if (context.email === '') {
            reject(t('Enter your email'));
            return;
          }
          if (!validator.isEmail(context.email)) {
            reject(t('Invalid e-mail address'));
            return;
          }
          const result = await sendResetPasswordToken(context.email);
          if (result.error) reject(result.error);
          else resolve(result);
        }),
    },
  }
);

export const resetPasswordMachine = createMachine(
  {
    id: 'resetPassword',
    context: {
      email: '',
      error: '',
      isRegistration: false,
    },
    initial: 'enterPassword',
    states: {
      enterPassword: {
        tags: ['enterPassword'],
        entry: [log((context, _) => ['enterPassword', context])],
        on: {
          RESET_ERROR: {
            actions: assign({
              error: '',
            }),
          },
          SUBMIT: {
            actions: assign({
              password: (_, event) => event.payload.password,
              token: (_, event) => event.payload.token,
              email: (_, event) => event.payload.email,
            }),
            target: 'resetting',
          },
        },
      },

      resetting: {
        tags: ['enterPassword', 'loading'],
        entry: [log((context, _) => ['resetting', context])],
        invoke: {
          src: 'resetPassword',
          onDone: {
            target: 'success',
            actions: [
              assign({
                error: '',
                token: '',
                isRegistration: (_, event) => event.data,
              }),
            ],
          },
          onError: {
            target: 'enterPassword',
            actions: assign({
              error: (_, event) => event.data,
            }),
          },
        },
      },

      success: {
        tags: ['success'],
        entry: log((context, _) => ['success', context]),
        type: 'final',
      },
    },
  },
  {
    services: {
      resetPassword: (context, event) =>
        new Promise(async (resolve, reject) => {
          if (context.email === '') {
            reject(t('Enter your email'));
            return;
          }
          if (!validator.isEmail(context.email)) {
            reject(t('Invalid e-mail address'));
            return;
          }
          if (context.password === '') {
            reject(t('Enter the password'));
            return;
          }

          const resetResult = await resetPassword(
            context.email,
            context.token,
            context.password
          );

          if (resetResult.error) reject(resetResult.error);
          else {
            const result = await login(context.email, context.password);
            if (result.error) reject(t('Failed to reset your password'));
            else resolve(resetResult.verified);
          }
        }),
    },
  }
);

export const loginMachine = createMachine(
  {
    id: 'login',
    context: {
      email: '',
      password: '',
      error: '',
      sendTime: 0,
      isRegistration: false,
    },
    initial: 'enterCredentials',
    states: {
      enterCredentials: {
        tags: ['login'],
        entry: [log((context, _) => ['enterCredentials', context])],
        on: {
          RESET_ERROR: {
            actions: assign({
              error: '',
            }),
          },
          SUBMIT: {
            actions: assign({
              isRegistration: false,
              email: (_, event) => event.payload.email,
              password: (_, event) => event.payload.password,
            }),
            target: 'validatingCredentials',
          },
        },
      },
      validatingCredentials: {
        tags: ['login', 'loading'],
        entry: [log((context, _) => ['validatingCredentials', context])],
        invoke: {
          src: 'login',
          onDone: {
            target: 'success',
            actions: assign({
              error: '',
              password: '',
            }),
          },
          onError: [
            {
              target: 'enterCode',
              cond: (_, event) => event.data === 'Verify your email',
              actions: [
                assign({
                  error: (context, event) => '',
                }),
              ],
            },
            {
              target: 'enterCredentials',
              actions: [
                assign({
                  error: (_, event) => event.data,
                  password: '',
                }),
              ],
            },
          ],
        },
      },

      enterCode: {
        tags: ['enterCode'],
        entry: [log((context, _) => ['enterCode', context])],
        on: {
          RESEND: {
            target: 'resending',
          },
          RESET_ERROR: {
            actions: assign({
              error: '',
            }),
          },
          SUBMIT: {
            target: 'validatingCode',
          },
          BACK: {
            target: 'enterCredentials',
          },
        },
      },
      resending: {
        tags: ['enterCode', 'loading'],
        entry: [log((context, _) => ['resending', context])],
        invoke: {
          src: 'resendCode',
          onDone: {
            target: 'enterCode',
            actions: assign({
              error: '',
              sendTime: () => Date.now(),
            }),
          },
          onError: {
            target: 'enterCode',
            actions: assign({
              error: (context, event) => event.data,
            }),
          },
        },
      },

      validatingCode: {
        tags: ['enterCode', 'loading'],
        entry: log((context, _) => ['validatingCode', context]),
        invoke: {
          src: 'validateCode',
          onDone: {
            target: 'validatingCredentials',
            actions: assign({
              isRegistration: true,
              error: '',
              sendTime: 0,
            }),
          },
          onError: {
            target: 'enterCode',
            actions: assign({
              error: (_, event) => event.data,
            }),
          },
        },
      },
      success: {
        entry: [log((context, _) => ['success', context])],
        type: 'final',
      },
    },
  },
  {
    services: {
      login: (context, event) =>
        new Promise(async (resolve, reject) => {
          if (context.email === '') {
            reject(t('Enter your email'));
            return;
          }
          if (!validator.isEmail(context.email)) {
            reject(t('Invalid e-mail address'));
            return;
          }
          if (context.password === '') {
            reject(t('Enter the password'));
            return;
          }
          const result = await login(context.email, context.password);
          if (result.error === 'user not found')
            reject(t('User is not registered'));
          else if (result.error === 'user not verified')
            reject('Verify your email');
          //No need transl as it not shown to user
          else if (result.error === 'wrong credentials')
            reject(t('Invalid login or password'));
          else if (result.error) reject(result.error);
          else resolve(result);
        }),

      resendCode: (context) =>
        new Promise(async (resolve, reject) => {
          const result = await sendVerificationCode(context.email);
          if (result.error) reject(result.error);
          else resolve(result);
        }),

      validateCode: (context, event) =>
        new Promise(async (resolve, reject) => {
          if (event.payload.code === '') {
            reject(t('Invalid code'));
            return;
          }
          const result = await verifyEmail(context.email, event.payload.code);
          if (result.error) reject(result.error);
          else {
            const result = await login(context.email, context.password);
            if (result.error) reject(t('Invalid code'));
            else resolve(result);
          }
        }),
    },
  }
);

export const signUpVerificationMachine = createMachine(
  {
    id: 'signUpVerification',
    context: {
      error: '',
    },
    initial: 'verifyEmail',
    states: {
      verifyEmail: {
        tags: ['verifyEmail'],
        entry: [log((context, _) => ['verifyEmail', context])],
        on: {
          RESET_ERROR: {
            actions: assign({
              error: '',
            }),
          },
          SUBMIT: {
            actions: assign({
              loginToken: (_, event) => event.payload.loginToken,
              email: (_, event) => event.payload.email,
              code: (_, event) => event.payload.code,
            }),
            target: 'verifying',
          },
        },
      },

      verifying: {
        tags: ['verifyEmail', 'loading'],
        entry: [log((context, _) => ['verifying', context])],
        invoke: {
          src: 'validateCode',
          onDone: {
            target: 'success',
            actions: [
              assign({
                error: '',
                loginToken: '',
              }),
            ],
          },
          onError: {
            target: 'error',
            actions: assign({
              error: (_, event) => event.data,
            }),
          },
        },
      },

      error: {
        tags: ['error'],
        entry: log((context, _) => ['error', context]),
        type: 'final',
      },

      success: {
        tags: ['success'],
        entry: log((context, _) => ['success', context]),
        type: 'final',
      },
    },
  },

  {
    services: {
      validateCode: (_, event) =>
        new Promise(async (resolve, reject) => {
          if (event.payload.code === '') {
            reject(t('Invalid code'));
            return;
          }
          if (event.payload.email === '') {
            reject(t('Invalid email'));
            return;
          }
          if (event.payload.loginToken === '') {
            reject(t('Invalid token'));
            return;
          }
          const currentSession = loadPersistentState('session');
          const currentEmail = loadPersistentState('email');
          if (currentSession?.token && event.payload.email === currentEmail)
            resolve();

          const result = await verifyEmail(
            event.payload.email,
            event.payload.code
          );
          if (result.error) reject(result.error);
          else {
            const result = await loginWithToken(event.payload.loginToken);
            if (result.error) {
              if (result.error === 'login token expired')
                reject(t('Link is expired'));
              if (result.error === 'user not verified')
                reject(t('Invalid code'));
              if (result.error === 'login token not found')
                reject(t('Invalid token'));
              else reject(t('Unknown error: ' + result.error));
            } else resolve(result);
          }
        }),
    },
  }
);

export const signUpMachine = createMachine(
  {
    id: 'signUp',
    context: {
      email: '',
      error: '',
      sendTime: 0,
    },
    initial: 'enterEmail',
    states: {
      enterEmail: {
        tags: ['enterEmail'],
        entry: [log((context, _) => ['enterEmail', context])],
        on: {
          RESET_ERROR: {
            actions: assign({
              error: '',
            }),
          },
          SUBMIT: {
            target: 'validatingEmail',
          },
        },
      },
      validatingEmail: {
        tags: ['enterEmail', 'loading'],
        entry: [
          log((context, event) => ['validatingEmail', context, event]),
          assign({
            email: (_, event) => event.payload.email,
          }),
        ],
        invoke: {
          src: 'validateEmail',
          onDone: {
            target: 'enterPassword',
            actions: assign({
              error: '',
            }),
          },
          onError: {
            target: 'enterEmail',
            actions: assign({
              error: (_, event) => event.data,
            }),
          },
        },
      },
      enterPassword: {
        tags: ['enterPassword'],
        entry: [log((context, _) => ['enterPassword', context])],
        on: {
          RESET_ERROR: {
            actions: assign({
              error: '',
            }),
          },
          BACK: {
            target: 'enterEmail',
            actions: assign({
              error: '',
            }),
          },
          SUBMIT: {
            actions: assign({}),
            target: 'submitting',
          },
        },
      },
      submitting: {
        tags: ['enterPassword', 'loading'],
        entry: [
          log((context, event) => ['submitting', context, event]),
          assign({
            password: (_, event) => event.payload.password,
          }),
        ],
        invoke: {
          src: 'register',
          onDone: {
            target: 'enterCode',
            actions: assign({
              error: '',
              sendTime: () => Date.now(),
            }),
          },
          onError: {
            target: 'enterPassword',
            actions: assign({
              error: (context, event) => event.data,
              password: '',
            }),
          },
        },
      },
      enterCode: {
        tags: ['enterCode'],
        entry: [log((context, _) => ['enterCode', context])],
        on: {
          RESEND: {
            target: 'resending',
          },
          RESET_ERROR: {
            actions: assign({
              error: '',
            }),
          },
          SUBMIT: {
            target: 'validatingCode',
          },
          BACK: {
            target: 'enterEmail',
            actions: assign({
              sendTime: 0,
              password: '',
            }),
          },
        },
      },
      resending: {
        tags: ['enterCode', 'loading'],
        entry: [log((context, _) => ['resending', context])],
        invoke: {
          src: 'resendCode',
          onDone: {
            target: 'enterCode',
            actions: assign({
              error: '',
              sendTime: () => Date.now(),
            }),
          },
          onError: {
            target: 'enterCode',
            actions: assign({
              error: (context, event) => event.data,
            }),
          },
        },
      },
      validatingCode: {
        tags: ['enterCode', 'loading'],
        entry: log((context, event) => ['validatingCode', context]),
        invoke: {
          src: 'validateCode',
          onDone: {
            target: 'success',
            actions: assign({
              error: '',
              sendTime: 0,
            }),
          },
          onError: {
            target: 'enterCode',
            actions: assign({
              error: (context, event) => event.data,
            }),
          },
        },
      },
      success: {
        tags: ['success'],
        entry: log((context, event) => ['success', context]),
        type: 'final',
      },
    },
  },
  {
    services: {
      validateEmail: (_, event) =>
        new Promise(async (resolve, reject) => {
          if (!event.payload.email || event.payload.email === '') {
            reject(t('Enter your email'));
            return;
          }
          if (!validator.isEmail(event.payload.email))
            reject(t('Invalid e-mail address'));
          else resolve();
        }),

      resendCode: (context) =>
        new Promise(async (resolve, reject) => {
          const result = await sendVerificationCode(context.email);
          if (result.error) reject(result.error);
          else resolve(result);
        }),

      validateCode: (context, event) =>
        new Promise(async (resolve, reject) => {
          if (event.payload.code === '') {
            reject(t('Invalid code'));
            return;
          }
          const result = await verifyEmail(context.email, event.payload.code);
          if (result.error) reject(result.error);
          else {
            const result = await login(context.email, context.password);
            if (result.error) reject(t('Invalid code'));
            else resolve(result);
          }
        }),
      register: (context, event) =>
        new Promise(async (resolve, reject) => {
          if (event.payload.password === '') {
            reject(t('Enter the password'));
            return;
          }
          const result = await register(context.email, event.payload.password);
          if (result.error === 'user already exists')
            reject(t('User is already registered'));
          else if (result.error === 'invalid parameter password=')
            reject(t('User is already registered'));
          else if (result.error) reject(result.error);
          else resolve(result);
        }),
    },
  }
);

export const userOnboardingMachine = createMachine({
  id: 'onboarding',
  context: {
    bets: 0,
    wins: 0,
    boxes: 0,
    prize: 0,

    betsCounter: 0,
    betsCounterLimit: 75,

    boxesCounter: 0,
    boxesCounterLimit: 200,

    winsCounter: 0,
    winsCounterLimit: 15,

    prizeCounter: 0,
    prizeCounterLimit: 50,

    boxesWinBet: 0, //Total bets which won

    boxesExpired: 0,
    boxesExpiredBet: 0,

    emptyBoxesWin: 0,
    emptyBoxesBet: 0,
    emptyBoxesExpired: 0,
    emptyBoxesExpiredBet: 0,
  },
  initial: 'playing',
  states: {
    newcomer: {
      entry: log((context, _) => context),
      on: {
        BET: {
          actions: assign({
            bets: 1,
            boxes: (_, event) => event.payload.boxes,
          }),
          target: 'playing',
        },
      },
    },
    playing: {
      on: {
        RESET: [
          {
            actions: [
              assign({
                bets: 0,
                betsCounter: 0,
                betsCounterLimit: 0,
                boxes: 0,
                boxesCounter: 0,
                boxesCounterLimit: (context, event) =>
                  event.payload?.boxesCounterLimit
                    ? event.payload.boxesCounterLimit
                    : context.boxesCounterLimit,
                wins: (_, event) =>
                  event.payload?.wins ? event.payload.wins : 0,
                winsCounter: (_, event) =>
                  event.payload?.winsCounter ? event.payload.winsCounter : 0,
                winsCounterLimit: (context, event) =>
                  event.payload?.winsCounterLimit
                    ? event.payload.winsCounterLimit
                    : context.winsCounterLimit,
                prize: 0,
                prizeCounter: 0,
                prizeCounterLimit: (context, event) =>
                  event.payload?.prizeCounterLimit
                    ? event.payload.prizeCounterLimit
                    : context.prizeCounterLimit,
                boxesWinBet: 0,
                boxesExpired: 0,
                boxesExpiredBet: 0,
                emptyBoxesWin: 0,
                emptyBoxesBet: 0,
                emptyBoxesExpired: 0,
                emptyBoxesExpiredBet: 0,
              }),
            ],
          },
        ],
        BET: [
          {
            actions: [
              assign({
                bets: (context) => context.bets + 1,
                betsCounter: (context) => context.betsCounter + 1,
                boxes: (context, event) => context.boxes + event.payload.boxes,
                boxesCounter: (context, event) =>
                  context.boxesCounter + event.payload.boxes,
              }),
            ],
          },
        ],
        WIN_OR_LOSE: [
          {
            cond: (context, event) =>
              (event.payload.executed || 0) + context.winsCounter >=
              context.winsCounterLimit,
            actions: [
              assign({
                wins: (context, event) => context.wins + event.payload.wins,
                prize: (context, event) => context.prize + event.payload.prize,
                winsCounter: (context, event) =>
                  context.winsCounter + (event.payload.executed || 0),
                prizeCounter: (context, event) =>
                  context.prizeCounter + event.payload.prize,

                boxesWinBet: (context, event) =>
                  context.boxesWinBet + event.payload.boxesWinBet,

                boxesExpired: (context, event) =>
                  context.boxesExpired + event.payload.boxesExpired,
                boxesExpiredBet: (context, event) =>
                  context.boxesExpiredBet + event.payload.boxesExpiredBet,

                emptyBoxesWin: (context, event) =>
                  context.emptyBoxesWin + event.payload.emptyBoxesWin,
                emptyBoxesBet: (context, event) =>
                  context.emptyBoxesBet + event.payload.emptyBoxesBet,
                emptyBoxesExpired: (context, event) =>
                  context.emptyBoxesExpired + event.payload.emptyBoxesExpired,
                emptyBoxesExpiredBet: (context, event) =>
                  context.emptyBoxesExpiredBet +
                  event.payload.emptyBoxesExpiredBet,
              }),
              'notifyOnLimit',
            ],
          },
          {
            actions: [
              assign({
                wins: (context, event) => context.wins + event.payload.wins,
                prize: (context, event) => context.prize + event.payload.prize,
                winsCounter: (context, event) =>
                  context.winsCounter + (event.payload.executed || 0),
                prizeCounter: (context, event) =>
                  context.prizeCounter + event.payload.prize,

                boxesWinBet: (context, event) =>
                  context.boxesWinBet + event.payload.boxesWinBet,

                boxesExpired: (context, event) =>
                  context.boxesExpired + event.payload.boxesExpired,
                boxesExpiredBet: (context, event) =>
                  context.boxesExpiredBet + event.payload.boxesExpiredBet,

                emptyBoxesWin: (context, event) =>
                  context.emptyBoxesWin + event.payload.emptyBoxesWin,
                emptyBoxesBet: (context, event) =>
                  context.emptyBoxesBet + event.payload.emptyBoxesBet,
                emptyBoxesExpired: (context, event) =>
                  context.emptyBoxesExpired + event.payload.emptyBoxesExpired,
                emptyBoxesExpiredBet: (context, event) =>
                  context.emptyBoxesExpiredBet +
                  event.payload.emptyBoxesExpiredBet,
              }),
            ],
          },
        ],
      },
    },
  },
});
