import { useEffect } from 'react';
import { Loader } from '../../pages/loader';
import { isAndroid, isIos, isNativeApp } from '../utils';
import { loadPersistentState } from '../utils/persist';
import { useLoggerContext } from './use-logger';
import { useNativeAppContext } from './use-native-app';

export function AdMobContainer({ format, onDone, onFail, onShowing }) {
  const logger = useLoggerContext();
  const handleShowAdMob = (event) => {
    logger?.event('adMob', { format, event: event?.data });
    if (event?.name === 'done') onDone && onDone(event?.data);
    if (event?.name === 'failed') onFail && onFail(event?.data);
    if (event?.name === 'showing') onShowing && onShowing(event?.data);
  };

  //format = "Long" | "Short"
  function getAdmobByFormat(format = '') {
    const settings = loadPersistentState('settings');

    //key = adMob[Adnroid | Ios][Short | Long | empty][Test | empty]
    const key = `adMob${isAndroid() ? 'Android' : ''}${
      isIos() ? 'Ios' : ''
    }${format}${window?.location?.hostname === 'boxo.trade' ? '' : 'Test'}`;

    const adMobForPlatform = settings ? settings[key] : null;

    logger.event('adMob', { showing: adMobForPlatform });

    const adMobFallback = {
      adType: 'rewardedInterstitial',
      adUnitId: 'ca-app-pub-3940256099942544/6978759866',
    };
    const adMob = !adMobForPlatform ? adMobFallback : adMobForPlatform;

    return adMob;
  }

  const nativeApp = useNativeAppContext();
  useEffect(() => {
    if (!isNativeApp()) {
      onFail && onFail('AdMob is not supported');
      return;
    }
    const unsubscibe = nativeApp?.addAdEventListener(
      'onShowAdMob',
      handleShowAdMob
    );
    const { adType, adUnitId } = getAdmobByFormat(format);
    nativeApp?.showAdMob(adType, adUnitId);

    return () => {
      unsubscibe && unsubscibe();
    };
  }, []);

  return (
    <>
      <Loader overlay />
    </>
  );
}
