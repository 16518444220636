import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { ModalLayout } from '../shared/layout';
import { useLocalStorage } from '../shared/hooks/use-hooks';
import { SelectionList } from '../components/terminal';
import { useParams } from 'react-router-dom';
import { useLoggerContext } from '../shared/hooks/use-logger';
import validator from 'validator';

const initSubscription = [0, 1, 2];
export default function Subscription() {
  const [subscription, setSubscription] = useLocalStorage(
    'subscription',
    initSubscription
  );
  const [email, setEmail] = useState('');

  const switchers = [
    {
      id: 0,
      name: t('Bonuses'),
      descr: t('Rewards and bonuses'),
      type: 'switch',
    },
    {
      id: 1,
      name: t('VIP'),
      descr: t('Special offers'),
      type: 'switch',
    },
    {
      id: 2,
      name: t('Newsletters'),
      descr: t('The latest updates'),
      type: 'switch',
    },
  ];

  const { email: emailParam } = useParams();

  useEffect(() => {
    try {
      const newEmail = emailParam ? atob(emailParam) : '';
      if (validator.isEmail(newEmail)) setEmail(newEmail);
      else throw new Error('Invalid email');
    } catch {
      setEmail('');
      console.error('Subscription: can not parse email parameter:', emailParam);
      if (process.env.NODE_ENV === 'production')
        logger &&
          logger.error(
            'Subscription: can not parse email parameter:',
            emailParam
          );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailParam]);

  const logger = useLoggerContext();

  useEffect(() => {
    console.log('subscription');

    logger &&
      email &&
      logger.action('subscription', { email, value: subscription.toString() });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscription.toString()]);

  useEffect(() => {}, []);

  return (
    <div className="app">
      <ModalLayout>
        <div className="modal-header">
          <h1 className="text-center text-default">{t('Email settings')}</h1>
          <div className="text-muted text-center">
            {t('Manage your email subscription ')}
          </div>
          <div
            className="text-muted text-center"
            style={{
              whiteSpace: 'nowrap',
              width: '100%',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {email}
          </div>
        </div>

        {!email && (
          <div className="invalid-feedback text-center">
            {t('Invalid email address')}
          </div>
        )}
        {email && (
          <div className="modal-body">
            <SelectionList
              header={t('Receive emails')}
              items={switchers.map((s, idx) => ({
                ...s,
                value:
                  subscription && Array.isArray(subscription)
                    ? subscription.includes(idx)
                    : true,
              }))}
              onClick={(idx, isChecked) => {
                if (isChecked) {
                  if (!subscription?.includes(idx)) {
                    const newSubscription = [...subscription, idx];
                    setSubscription(newSubscription);
                  }
                } else {
                  const newSubscription = (
                    Array.isArray(subscription)
                      ? subscription
                      : initSubscription
                  ).filter((s) => s !== idx);
                  setSubscription(newSubscription);
                }
              }}
            />
          </div>
        )}
      </ModalLayout>
    </div>
  );
}
