import { useEffect, useState } from 'react';
import anime from 'animejs/lib/anime.es.js';

export function AnimatedDot({ repeat = 0, from, to, onComplete }) {
  const dotClassName = 'animated-dot';
  const [dots, setDots] = useState([]);
  const count = 100;
  const size = 9;
  const fromY = from.y - size / 2;
  const toY = to.y - size / 2;
  const fromX = from.x - size / 2;
  const toX = to.x - size / 2;
  let x = fromX;
  let y = fromY;
  const stepX = (toX - x) / count;
  const stepY = (toY - y + size / 2) / count;

  const [showingCount, setShowingCount] = useState(0);
  const [curve, setCurve] = useState(0);

  function generateDots() {
    let d = [];
    for (var i = 0; i <= count; i += 1) {
      d.push({ x, y, size });
      x =
        x +
        stepX +
        (Math.cos((1 * Math.PI * y) / (toY - fromY)) * curve) / count;
      y = y + stepY;
    }
    return d;
  }

  useEffect(() => {
    if (!repeat) return;
    if (showingCount) return;
    setCurve(Math.random() * 300 - 150);
    setShowingCount(repeat);
    setDots(generateDots());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [repeat]);

  useEffect(() => {
    const opacity = [];

    const coins = Math.max(2, Math.min(6, repeat));

    for (let i = 0; i < coins; i++) {
      opacity.push({ value: 1, duration: 10, delay: anime.stagger(2) });
      opacity.push({ value: 0, duration: 10, delay: anime.stagger(0) });
    }

    dots.length > 0 &&
      anime({
        loop: false,
        easing: 'linear',
        opacity: opacity,

        targets: document.querySelectorAll(`.${dotClassName}`),
        update: function (anim) {
          if (anim.progress < 100) return;
          setShowingCount(0);
          onComplete(repeat);
          setDots([]);
        },
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dots]);

  return (
    <div
      style={{
        position: 'relative',
        zIndex: '101',
      }}
    >
      {dots.map((dot, idx) => (
        <div
          key={idx}
          className="animated-dot"
          style={{
            height: `${dot.size}px`,
            width: `${dot.size}px`,
            left: `${dot.x}px`,
            top: `${dot.y}px`,
          }}
        />
      ))}
    </div>
  );
}

export function AnimatedCongrats({ from, to, state }) {
  return (
    <div
      style={{
        position: 'relative',
        zIndex: '90',
      }}
    >
      {state?.context.congrats?.map((c) => (
        <div
          key={c.id}
          className="animated-congrats"
          style={{
            left: `${from.x}px`,
            top: `${from.y}px`,
          }}
        >
          {c.text}
        </div>
      ))}
    </div>
  );
}
