import { t } from 'i18next';
import { useRef } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { TodoList } from '../components/terminal';
import { useInterval } from '../shared/hooks/use-interval';
import { useNativeAppContext } from '../shared/hooks/use-native-app';
import { useSoundContext } from '../shared/hooks/use-sound';
import { ModalLayout } from '../shared/layout';
import { priceFmt } from '../shared/utils';

export function Loader({ onClick, overlay, redirect = '' }) {
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    redirect &&
      setTimeout(
        () =>
          location.pathname === window.location.pathname &&
          navigate(redirect, { replace: true }),
        1000
      );
  }, []);

  return (
    <div className={`loader ${overlay ? 'overlay' : ''}`} onClick={onClick} />
  );
}

export function ConnectionLoader({
  price = null,
  centralPoint,
  asset = 'Bitcoin',
  onConnected,
}) {
  const nativeApp = useNativeAppContext();
  const refPrice = useRef();
  refPrice.current = price;
  const [step, setStep] = useState(0);
  useEffect(() => {
    if (step === 0) setTimeout(() => setStep(1), 500);
    if (step === 1) setTimeout(() => setStep(!refPrice?.current ? 0 : 2), 1800);
    // (step === 2) button click
    if (step === 3) setTimeout(() => setStep(4), 800);
    if (step === 4) onConnected && onConnected();
  }, [step]);

  return (
    <>
      {step < 4 && (
        <div className={`connection-loader ${step < 3 ? 'overlay' : ''}`}>
          {step <= 2 && (
            <div style={{ opacity: step === 0 ? 0 : 1 }}>
              <ModalLayout onClose={null} onBack={null}>
                <div className="modal-header">
                  <h1 className="text-center text-default">
                    {t('Connecting')}...
                  </h1>
                </div>
                <div className="modal-body no-scroll">
                  <img
                    src="/img/fox/calling.png"
                    style={{
                      height: '60vh',
                      left: '50%',
                      position: 'absolute',
                      transform: 'translateX(-50%)',
                    }}
                  />
                </div>
              </ModalLayout>
            </div>
          )}

          {(step === 2 || step === 3) && (
            <>
              <ModalLayout
                hideNavigation
                onClose={null}
                onBack={null}
                isTransparent={step >= 3 ? true : false}
              >
                <div className="modal-header"></div>
                <div className="modal-body">
                  <div
                    className="text-center text-default text-nowrap"
                    style={{
                      position: 'absolute',
                      left: step === 2 ? centralPoint.x : 0,
                      top: step === 2 ? centralPoint.y : 0,
                      transform:
                        step === 2
                          ? `translate(-40%, -35px) scale(2.5)`
                          : `translate(12px, 16px) scale(1)`,
                      transition:
                        'left 0.5s ease-in-out, top 0.5s ease-in-out, transform 0.5s ease-in-out',
                    }}
                  >
                    {asset}
                    <img
                      src="/img/btc.svg"
                      alt=""
                      style={{
                        width: '18px',
                        marginTop: '-3px',
                        marginLeft: '4px',
                        marginRight: '4px',
                        opacity: 0.9,
                      }}
                    />
                  </div>

                  <div
                    className="text-center"
                    style={{
                      position: 'absolute',
                      left: centralPoint.x,
                      top: centralPoint.y,
                      transform:
                        step === 2
                          ? `translate(-50%, 30px) `
                          : `translate(-50%, 78px)`,
                      transition: 'all 0.5s ease-in-out',
                    }}
                  >
                    <div
                      style={{
                        transform:
                          step === 2
                            ? `translateY(-20px) scale(3)`
                            : `scale(1)`,
                        transition: 'all 0.5s ease-in-out',
                      }}
                    >
                      <small
                        className="text-muted"
                        style={{
                          fontWeight: 500,
                        }}
                      >
                        ${priceFmt(price, 2, 2)}
                      </small>
                    </div>

                    {step === 2 && (
                      <small className="text-uppercase text-default">
                        {t('Current real-time price')}
                      </small>
                    )}
                  </div>
                </div>

                {step === 2 && (
                  <div className="modal-footer no-mobile-keyboard">
                    <div
                      className="btn btn-primary"
                      role="button"
                      onClick={() => {
                        setStep(3);
                        nativeApp.haptics('success');
                      }}
                    >
                      {t(`Continue`)}
                    </div>
                  </div>
                )}
              </ModalLayout>
            </>
          )}
        </div>
      )}
    </>
  );
}
