import { useEffect, useState } from 'react';
import { Howl } from 'howler';
import { useLocalStorage } from './use-hooks';
import { useRef } from 'react';
import { createContext } from 'react';
import { useMemo } from 'react';
import { useContext } from 'react';
import { useLoggerContext } from './use-logger';

const Sound = createContext();
export function SoundContext({ children }) {
  const sound = useSound();
  const persistedSound = useMemo(() => sound, [sound]);
  return <Sound.Provider value={persistedSound}>{children}</Sound.Provider>;
}
export const useSoundContext = () => useContext(Sound);

const emptyFunction = () => {};
const emptySounds = {
  hits: emptyFunction,
  html5Click: emptyFunction,
  normalClick: emptyFunction,
  wetClick: emptyFunction,
  highClick: emptyFunction,
  levelUp: emptyFunction,
  bonus: emptyFunction,
  win: emptyFunction,
};

function useSound() {
  const logger = useLoggerContext();

  const [isInited, setIsInited] = useState(false);
  const [sounds, setSounds] = useState(emptySounds);
  const [enableSonuds] = useLocalStorage('enable-sounds', true);

  const refenableSonuds = useRef();
  refenableSonuds.current = enableSonuds;

  function init(mp3, volume = 1, rate = 1, html5 = false, loop = false) {
    const s = new Howl({
      src: [mp3],
      autoplay: false,
      preload: true,
      loop: loop,
      html5,
      rate,
      volume: volume,
    });
    return () => {
      if (!refenableSonuds.current) return;
      try {
        s && s.play();
      } catch {
        if (process.env.NODE_ENV !== 'production')
          alert('failed to start audio');
      }
    };
  }

  function initHits() {
    const hitDuration = 160;
    const hits = new Howl({
      src: ['/sounds/hits.mp3'],
      autoplay: false,
      html5: false,
      preload: true,
      sprite: {
        hit1: [0, hitDuration],
        hit2: [342, hitDuration],
        hit3: [645, hitDuration],
        hit4: [1110, hitDuration],
        hit5: [1474, hitDuration],
      },
      volume: 0.7,
      rate: 2,
    });
    return (i) => {
      if (!refenableSonuds.current) return;
      if (enableSonuds && i <= 8) hits.play(`hit${i}`);
    };
  }

  function initWins() {
    const wins = [
      init('/sounds/win1.mp3', 0.2, 1),
      init('/sounds/win2.mp3', 0.2, 1),
      init('/sounds/win3.mp3', 0.2, 1),
    ];
    return (i) => {
      if (i >= 0 && i <= 2) wins[i]();
    };
  }

  useEffect(() => {
    if (isInited) return;

    try {
      const newSounds = {
        hits: initHits(),
        html5Click: init('/sounds/silence.mp3', 0.7, 1, true),
        normalClick: init('/sounds/normal-click.mp3', 0.5, 1),
        wetClick: init('/sounds/wet-click.mp3', 0.4, 1),
        highClick: init('/sounds/high-click.mp3'),
        levelUp: init('/sounds/level-up.mp3', 0.1, 1),
        bonus: init('/sounds/bonus.mp3', 0.5, 1),
        notification: init('/sounds/notification.mp3', 0.2, 1),
        win: initWins(),
      };
      setSounds(newSounds);
      setIsInited(true);
    } catch (e) {
      //setSounds(emptySounds);
      //setIsInited(false);
      logger.event('error', { msg: 'Can not init sounds', error: e.message });
      //if (process.env.NODE_ENV !== 'production') alert('sounds init error');
    }
  }, [isInited]);

  return sounds;
}
