import { assign, send, log, createMachine } from 'xstate';
import { v4 as uuid } from 'uuid';

export const animatedCongratsMachine = createMachine({
  id: 'animatedCongrats',
  context: {
    congrats: [],
  },
  initial: 'idle',
  states: {
    idle: {
      entry: [log((context, _) => ['idle', context])],
      on: {
        ADD: {
          internal: true,
          actions: [
            assign({
              congrats: (context, event) => [
                ...context.congrats,
                { id: uuid(), text: event.payload.text, addTime: Date.now() },
              ],
            }),
            send('CLEAN', { delay: 5000 }),
          ],
        },
        CLEAN: {
          actions: [
            assign({
              congrats: (context) =>
                context.congrats.filter((c) => Date.now() - c.addTime < 5000),
            }),
          ],
        },
      },
    },

    cleaning1: {
      invoke: {
        //id: 'cleaning',
        src: (context, event) =>
          new Promise((resolve) =>
            setTimeout(
              () => resolve(),
              //tastyPlants.filter((plant) => plant.includes(context.input))
              1500
            )
          ),
        onDone: {
          target: 'idle',
          actions: assign({
            //filteredTastyPlants: (context, event) => event.data,
          }),
        },
      },
    },
  },
});
