import { isAndroid } from './utils';
import { t } from 'i18next';

export function SystemDialog({ show, title, message, onAction, actions = [] }) {
  const android = isAndroid();
  return (
    <>
      {show && (
        <div
          style={{
            position: 'absolute',
            content: '',
            left: 0,
            top: 0,
            width: '100vw',
            height: '100vh',
            minHeight: '100vh',
            overflow: 'hidden',
            zIndex: 1998,
            backgroundColor: 'transparent', //#0000009e',
          }}
          onClick={() => onAction(-1)}
        />
      )}

      {show && (
        <div
          style={{
            position: 'absolute',
            overflow: 'hidden',
            top: '50%',
            left: '50%',
            minWidth: '100%',
            transform: 'translate(-50%, -50%)',
            transition:
              'transform 450ms ease-in-out, opacity 450ms ease-in-out',
            opacity: show ? 100 : 0,
            zIndex: 1999,
          }}
        >
          <div
            className=""
            style={{
              margin: '14vw',
              boxShadow: '0px 5px 15px var(--theme-overlay-transparent)',
              backgroundColor: 'white',
              padding: '12px 24px 6px',
              borderRadius: false ? '' : '16px',
              fontFamily: android ? 'Google Sans' : '',
            }}
          >
            <div
              className={android ? '' : 'text-center'}
              style={{
                color: 'black',
                fontWeight: 500,
                fontSize: '16px',
                lineHeight: '20px',
                margin: '10px 0 6px',
              }}
            >
              {title}
            </div>
            <div
              className={android ? '' : 'text-center'}
              style={{
                color: 'black',
                fontWeight: 400,
                lineHeight: '16px',
                fontSize: '14px',
              }}
            >
              {message}
            </div>
            {actions.length === 0 && (
              <div
                className={`btn btn-secondary ${
                  android ? 'text-end' : 'text-center'
                }`}
                role="button"
                onClick={() => onAction(0)}
                style={{
                  backgroundColor: 'white',
                  color: '#6495ED',
                  fontWeight: 500,
                  fontSize: '16px',
                  padding: '0 16px',
                }}
              >
                {t('Close')}
              </div>
            )}

            {actions.length > 0 && (
              <div className="row flex-nowrap g-0 mt-1 mb-1">
                {android && <div key={-1} className={`col`} />}
                {actions.map((action, idx) => {
                  return (
                    <div
                      key={idx}
                      className={`col btn btn-secondary text-center ${
                        android ? 'text-uppercase' : ''
                      }`}
                      role="button"
                      onClick={() => onAction(idx)}
                      style={{
                        backgroundColor: 'white',
                        color: '#6495ED',
                        fontWeight: 500,
                        fontSize: '16px',
                        padding: '0 16px',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {action}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}
