import { HttpStatusCode } from 'axios';
import { refreshToken } from './auth';
import { auth_api, auth_api_middleware } from './api-client';
import { persistState } from '../utils/persist';

export function handleError(error) {
  return {
    data: {
      error:
        error?.response?.data?.message ||
        error?.response?.data?.error?.message ||
        'something went wrong',
    },
  };
}

export async function maybe_refresh_auth_token(call) {
  const { api, refreshToken: currentRefreshToken } = auth_api();
  return call(api).catch(async (error) => {
    if (error?.response?.status === HttpStatusCode.Unauthorized) {
      try {
        const data = await refreshToken(currentRefreshToken);
        if (!!data && !data.error) {
          const { api: updatedApi } = auth_api();
          return call(updatedApi);
        } else {
          persistState('session', null);
          throw error;
        }
      } catch (e) {
        persistState('session', null);
        throw error;
      }
    } else throw error;
  });
}

export function handleMiddlewareError(error) {
  return {
    data: {
      error:
        error?.response?.data?.error?.message ||
        error?.message ||
        'something went wrong',
    },
  };
}

export async function maybe_refresh_auth_token_middlware(call) {
  const { api, refreshToken: currentRefreshToken } = auth_api_middleware();
  return call(api).catch(async (error) => {
    if (error?.response?.status === HttpStatusCode.Unauthorized) {
      try {
        const data = await refreshToken(currentRefreshToken);
        if (!!data && !data.error) {
          const { api: updatedApi } = auth_api_middleware();
          return call(updatedApi);
        } else {
          persistState('session', null);
          throw error;
        }
      } catch (e) {
        persistState('session', null);
        throw error;
      }
    } else throw error;
  });
}
