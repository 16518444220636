import { useEffect, useState } from 'react';
import ReactPixel from 'react-facebook-pixel';
import TiktokPixel from 'tiktok-pixel';

export function usePixel() {
  const [isFbActive, setIsFbActive] = useState(false);
  const [isTikTokActive, setIsTikTokActive] = useState(false);

  useEffect(() => {
    const initFb = () => {
      const advancedMatching = {};
      const options = {
        autoConfig: true,
        debug: false,
      };
      if (window.location.hostname === 'boxo-fun.online') {
        ReactPixel.init('1356254651724329', advancedMatching, options);
        setIsFbActive(true);
      }
    };

    const initTikTok = () => {
      const advancedMatching = {}; // optional, more info: https://ads.tiktok.com/help/article?aid=10007891
      const options = {
        debug: false, // enable logs
      };
      if (window.location.hostname === 'boxo.trade') {
        TiktokPixel.init('CSH5L7RC77U5IJO1SELG', advancedMatching, options);
        setIsTikTokActive(true);
      }
    };
    initTikTok();
    initFb();
  }, []);

  const pageView = () => {
    console.log('PIXEL - Page view', ReactPixel, TiktokPixel);
    if (isFbActive) ReactPixel?.pageView();
    if (isTikTokActive) TiktokPixel?.pageView();
  };

  const track = (event, data) => {
    console.log('PIXEL - track', event);
    if (isFbActive) ReactPixel?.track(event, data);
    if (isTikTokActive) TiktokPixel?.track(event, data);
  };

  return { pageView, track };
}
